import { ButtonProps as AntdButtonProps, Button as AntdButton } from 'antd';
import styled from 'styled-components';
import { colors } from '../constants';
import { UITheme } from '../theme';

enum ButtonVariant {
  primary = 'primary',
  secondary = 'secondary',
  tertiary = 'tertiary',
  quaternary = 'quaternary',
  danger = 'danger',
  pending = 'pending',
}

export const getColor = (theme: UITheme) => ({
  [ButtonVariant.primary]: {
    background: theme.groupID?.primary,
    color: colors.white,
  },
  [ButtonVariant.secondary]: {
    background: colors.grey,
    color: colors.white,
  },
  [ButtonVariant.tertiary]: {
    background: colors.green,
    color: colors.white,
  },
  [ButtonVariant.quaternary]: {
    background: colors.white,
    color: theme.groupID?.primary,
  },
  [ButtonVariant.danger]: {
    background: colors.red,
    color: colors.white,
  },
  [ButtonVariant.pending]: {
    background: '#4F9EFF',
    color: colors.white,
  },
});

type ButtonProps = AntdButtonProps & {
  $variant?: keyof typeof ButtonVariant;
  width?: number;
  maxWidth?: number;
};

export const Button = (props: ButtonProps) => {
  return <StyledButton {...props} />;
};

const StyledButton = styled(AntdButton)<ButtonProps>`
  &&& {
    border-radius: 8px;
    background: ${({ $variant = 'primary', theme }) =>
      getColor(theme)[$variant].background};
    color: ${({ $variant = 'primary', theme }) =>
      getColor(theme)[$variant].color};
    min-width: ${({ width }) => (width ? `${width}px` : 'auto')};
    outline: none;
    height: 40px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      color: ${({ $variant = 'primary', theme }) =>
        getColor(theme)[$variant].color};
    }

    &:disabled {
      background-color: ${({ theme }) => theme?.colors?.grey50 || '#8F8D90'};
      cursor: not-allowed;
    }
  }
`;
