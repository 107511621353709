import { Typography, TypographyProps } from 'antd'
import { TitleProps } from 'antd/lib/typography/Title'
import React from 'react'
import styled from 'styled-components'

const { Title: AntdTitle } = Typography

const Title = styled(AntdTitle) <TitleProps & {
    cursor?: boolean
}> `
    &&&{
        font-weight: 500;
        cursor: ${({ cursor }) =>  cursor ? 'pointer' : 'default'};
    }
`;


export const Text = ({ ...props }: TitleProps & {
    cursor?: boolean
}) => {
    return (
        <Title {...props} />
    )
}
