import { Button } from "antd";
import styled from "styled-components";
import { colors } from "../../constants";

export const FormWrapper = styled.section`
    background: ${colors.white};
    border: 1px solid ${colors.azureishWhite};
    padding: 32px;
    box-shadow: 0px 5px 6px 0px #0000000F;
`;

export const ButtonWrapper = styled.div`
    display: flex;
    gap: 18px;
    button {
        width: 100%;
    }
`;


export const RemovePostButton = styled(Button)`
    margin-top: 10px;
    cursor: pointer;
    color: ${colors.red};
    outline: none;
    border:none;
    box-shadow: none;
    display: flex;
    gap: 10px;
    align-items: center;

    // remove all hover effects
    :hover, :focus {
        color: ${colors.red};
        background: ${colors.white};
        border:none;
        outline: none;

    }
`

export const Copy = styled.span`
    font-size: 14px;
    cursor: pointer;
`

export const Label = styled.label`
    font-size: 10px !important;
`