import { createGlobalStyle } from 'styled-components';

export const GlobalCss = createGlobalStyle`
    * {
      font-family: 'Inter';
    } 

    *::-webkit-scrollbar {
      display: none;
    }
    * {
      -ms-overflow-style: none; 
      scrollbar-width: none;  
    }

    .cdx-mention{
      background: grey;
      color: white;
      
    }

    .react-renderer .items{
      background: #f5f5f5;
      padding: 10px;
      min-width: 200px;
      min-height: 150px;
      text-align: center;
      height: 200px;
      width: 500px;
      overflow-y: scroll;
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      gap: 5px;
    }
    .react-renderer .items button{
      padding: 2px 4px ;
      border-radius: 8px;
      border: 1px solid #ccc;
      background: #fff;
      font-size: 11px;
      width: fit-content;
    }


    .ant-notification-notice{
      padding:10px;
      border-radius: 12px;
      /* light shadow */
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1), 0px 4px 8px rgba(0, 0, 0, 0.1), 0px 8px 16px rgba(0, 0, 0, 0.1), 0px 16px 32px rgba(0, 0, 0, 0.1), 0px 32px 64px rgba(0, 0, 0, 0.1);
    }

    .ant-select-item-option-content{
      white-space: normal;
    }

    .ant-form-item-explain-error{
      font-size: 10px;
    }
    
    .dd .ant-modal-content {
      background: none;
      box-shadow: none;
    }
`;
