// import dotenv from 'dotenv'

// dotenv.config()

import 'antd/dist/antd.css';
import { ThemeProvider } from 'styled-components';
import { getCurrentDomain, theme } from '../components';
import { GlobalCss } from '../styles/global';
import { AuthProvider } from '../context/auth';
import {
  GroupIdAssetsProvider,
  useGroupAssetsContext,
} from '../context/groupIdColors';
import '../locales';
import { Switch, Route, BrowserRouter as Router } from 'react-router-dom';
import RouteModule from './route';
import { Suspense, useEffect } from 'react';
import { Spin } from 'antd';
import './index.css';

export default function MyApp() {
  useEffect(() => {
    //   Initial setup for development.
    console.log('environment:', process.env.NODE_ENV);
    if (process.env.NODE_ENV === 'development') {
      localStorage.setItem('token', process.env.REACT_APP_DEV_TOKEN);
    }
  }, []);
  return (
    <AuthProvider>
      <GroupIdAssetsProvider>
        <Layouts />
      </GroupIdAssetsProvider>
    </AuthProvider>
  );
}

const Layouts = () => {
  const { groupAssets } = useGroupAssetsContext();

  if (!groupAssets) {
    return <div>loading</div>;
  }

  const RouteComponents = () => {
    const themeDomain = getCurrentDomain();
    return RouteModule[themeDomain || 'marketing'].map((route, i) => {
      return (
        <Route
          path={route.path}
          exact={route.exact}
          key={i}
          component={route.component}
        />
      );
    });
  };

  return (
    <ThemeProvider theme={{ ...theme, groupID: groupAssets.colors }}>
      <GlobalCss />
      <Router>
        <Switch>
          <Suspense fallback={<Spin />}>{RouteComponents()}</Suspense>
        </Switch>
      </Router>
    </ThemeProvider>
  );
};
