import styled, { css } from 'styled-components';
import { useGroupAssetsContext } from '../../../../context/groupIdColors';
import { ResponsiveWrapper } from '../styles';
import { useAuthContext } from '../../../../context/auth';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import Dropdown, { IDropdown } from './dropdown';

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;

  @media (max-width: 768px) {
    display: none;
  }
`;

const NavList = styled.ul`
  margin: 0;
  list-style: none;
  display: flex;
  align-items: center;
  gap: 20px;
`;

const NavItem = styled.li`
  font-size: 14px;
  font-weight: 500;
`;

const NavLink = styled.a`
  color: #9093a4;
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
`;

const IconWrapper = styled.div`
  width: 36px;
  height: 36px;
  border: 1px solid #d7dbee;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
`;

const NotificationDot = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background: #4f9eff;
  position: absolute;
  top: 0;
  right: -5%;
  z-index: 1;
`;

const Logo = styled.img`
  width: auto;
  height: 40px;
  text-align: center;
  display: block;
`;

const Divider = styled.div`
  width: 27px;
  height: 0px;
  border-top: 1px solid #d7dbee;
  opacity: 1;
  transform: rotate(-58.78deg);
`;

const TopbarContainer = styled.div`
  padding: 20px;
  display: flex;
  justify-content: space-between;

  @media (max-width: 768px) {
    padding: 15px;
  }
`;

const LogoDividerContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

const InnerContainer = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

const GradientCircle = styled.div`
  width: 25px;
  height: 25px;
  border-radius: 100%;
  background: linear-gradient(137.16deg, #f06464 17.4%, #3c809d 100%);
`;

const Title = styled.h1`
  font-size: 14px;
  margin: 0;
  font-weight: 600;
  color: #2b2f44;
`;

const Button = styled.button`
  background: #f5f7fb;
  border: none;
  border-radius: 4px;
  padding: 4px 8px;
  margin-left: 10px;
  cursor: pointer;
  outline: none;
`;

const Topbar = () => {
  const { user, selectedGarage } = useAuthContext();
  const { groupAssets } = useGroupAssetsContext();
  const { colors, logo } = groupAssets;

  return (
    <TopbarContainer>
      <LogoDividerContainer>
        <Logo src={logo} alt="logo" />
        <Divider />
        <InnerContainer>
          <GradientCircle />
          <Title>{user?.isGroup ? user.groupName : user?.garageName}</Title>
        </InnerContainer>
        {user?.isGroup && selectedGarage?.garageName && (
          <ResponsiveWrapper
            hideOnMobile
            baseStyles={css`
              display: flex;
              align-items: center;
            `}
          >
            <Divider />
            <Title>{selectedGarage?.garageName}</Title>
          </ResponsiveWrapper>
        )}

        {user?.isGroup && <Dropdown />}
      </LogoDividerContainer>
      {/* <Container>
        <NavList>
          <NavItem>
            <NavLink href="/help">Help</NavLink>
          </NavItem>
          <NavItem>
            <NavLink href="/docs">Docs</NavLink>
          </NavItem>
        </NavList>
        <IconContainer>
          <IconWrapper>
            <NotificationDot />
            <svg
              width="12"
              height="13"
              viewBox="0 0 12 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6 0.130859C6.39844 0.130859 6.75 0.482422 6.75 0.880859V1.34961C8.46094 1.67773 9.75 3.20117 9.75 5.00586V5.61523C9.75 6.66992 10.1016 7.70117 10.7578 8.54492L11.1094 8.9668C11.25 9.1543 11.2734 9.38867 11.1797 9.57617C11.0859 9.76367 10.8984 9.88086 10.6875 9.88086H1.3125C1.07812 9.88086 0.890625 9.76367 0.796875 9.57617C0.703125 9.38867 0.726562 9.1543 0.867188 8.9668L1.21875 8.54492C1.875 7.70117 2.25 6.66992 2.25 5.61523V5.00586C2.25 3.20117 3.53906 1.67773 5.25 1.34961V0.880859C5.25 0.482422 5.57812 0.130859 6 0.130859ZM6 2.38086C4.54688 2.38086 3.375 3.57617 3.375 5.00586V5.61523C3.375 6.74023 3.04688 7.81836 2.4375 8.75586H9.53906C8.92969 7.81836 8.625 6.74023 8.625 5.61523V5.00586C8.625 3.57617 7.42969 2.38086 6 2.38086ZM7.5 10.6309C7.5 11.0293 7.33594 11.4277 7.05469 11.709C6.77344 11.9902 6.39844 12.1309 6 12.1309C5.60156 12.1309 5.20312 11.9902 4.92188 11.709C4.64062 11.4277 4.5 11.0293 4.5 10.6309H6H7.5Z"
                fill="#2B2F44"
              />
            </svg>
          </IconWrapper>
          <IconWrapper>
            <svg
              width="11"
              height="13"
              viewBox="0 0 11 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.375 3.13086C7.375 2.47461 7 1.86523 6.4375 1.51367C5.85156 1.18555 5.125 1.18555 4.5625 1.51367C3.97656 1.86523 3.625 2.47461 3.625 3.13086C3.625 3.81055 3.97656 4.41992 4.5625 4.77148C5.125 5.09961 5.85156 5.09961 6.4375 4.77148C7 4.41992 7.375 3.81055 7.375 3.13086ZM2.5 3.13086C2.5 2.07617 3.0625 1.0918 4 0.552734C4.91406 0.0136719 6.0625 0.0136719 7 0.552734C7.91406 1.0918 8.5 2.07617 8.5 3.13086C8.5 4.20898 7.91406 5.19336 7 5.73242C6.0625 6.27148 4.91406 6.27148 4 5.73242C3.0625 5.19336 2.5 4.20898 2.5 3.13086ZM1.39844 11.0059H9.57812C9.36719 9.5293 8.10156 8.38086 6.55469 8.38086H4.42188C2.875 8.38086 1.60938 9.5293 1.39844 11.0059ZM0.25 11.4512C0.25 9.13086 2.10156 7.25586 4.42188 7.25586H6.55469C8.875 7.25586 10.75 9.13086 10.75 11.4512C10.75 11.8262 10.4219 12.1309 10.0469 12.1309H0.929688C0.554688 12.1309 0.25 11.8262 0.25 11.4512Z"
                fill="#2B2F44"
              />
            </svg>
          </IconWrapper>
        </IconContainer>
      </Container> */}
    </TopbarContainer>
  );
};

export default Topbar;
