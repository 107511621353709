import { message, Row, Spin } from 'antd';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import fetchHook from '../constants/useFetchHook';
import _t from '../locales';
import { RedirectToCorrespondingUrl } from '../components';
import { IDropdown } from '../components/layouts/dashboard/components/dropdown';

export interface IAuthContext {
  user?: {
    id: string | number;
    groupId: string;
    email: string;
    languageId: number;
    groupName: string;
    garageName: string;
    garageId: string;
    isGroup: boolean;
    userId: string | number;
  };
  setUser: (user: any) => void;
  customer?: string;
  selectedGarage?: IDropdown;
  setSelectedGarage: (item: IDropdown) => void;
}

const AuthContext = createContext<IAuthContext>({
  setUser: () => {},
  setSelectedGarage: () => {},
});

export interface AuthProviderProps {
  children: React.ReactNode;
}

export const defaultSelectedGarage = { id: 0, garageName: 'All' } as const;

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const [user, setUser] = useState<IAuthContext['user']>();
  const [customer, setCustomer] = useState<string | undefined>();
  const [selectedGarage, setSelectedGarage] = useState<IDropdown | null>(
    defaultSelectedGarage
  );
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  /**
   * gets auth token from server
   */
  const getAuthToken = async (nonce?: string, customerData?: string) => {
    // if (getCurrentDomain() === 'claims') return
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { response, error } = await fetchHook({
      // add customer data if exists
      url: nonce
        ? `/auth/verify/${nonce}` +
          (customerData ? `?customerData=${customerData}` : '')
        : '/auth/me',
      method: 'get',
    });
    console.log(response, error);
    if (error || !response) {
      console.log((error as any).response.statusCode);
      message.error(t('toast.youAreNotLoggedIn'));
      // window.location.href = 'https://beta.igarage.nl/Users/Account/LogOn';
      return;
    }
    const {
      data: { token, ...other },
    } = response as any;

    if (nonce) {
      localStorage.setItem('token', response.data.token);
      // debugger
      console.log(response);
      RedirectToCorrespondingUrl(other as any);
    } else if (response) {
      console.log(response, (response as any)?.data);
      // setUser((response as any)?.data);

      // TODO: this is a quick fix - actual fix should be made on marketing BE
      setUser({
        ...response?.data,
        groupName: response?.data?.groupName?.includes('autobandenpartner')
          ? 'GRIP Expert'
          : response?.data?.groupName,
      });
    }
  };

  /**
   * checks the url if nonce exists then verifys  the nonce and gets the token
   */
  const checkUser = useCallback(async () => {
    // if (getCurrentDomain() === 'claims') return
    setLoading(true);
    const params = new URLSearchParams(window.location.search);
    const nonce = params.get('nonce');
    const customerData = params.get('customerData');
    const token = localStorage.getItem('token');
    try {
      if (nonce) {
        return await getAuthToken(nonce, customerData);
      } else if (token) {
        return await getAuthToken();
      }

      localStorage.clear();
      // @ts-ignore
      if (
        process.env.NODE_ENV === 'staging' ||
        process.env.NODE_ENV === 'development'
      ) {
        window.location.href = 'https://beta.igarage.nl/Users/Account/LogOn';
      } else {
        window.location.href = 'https://igarage.nl/Users/Account/LogOn';
      }
      message.error(t('toast.youAreNotLoggedIn'));
    } catch (error) {
      localStorage.clear();
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    checkUser().catch(console.error);
  }, [checkUser]);

  useEffect(() => {
    if (!user) {
      return;
    }
    _t.changeLanguage(['en', 'nl'][Number(user.languageId) - 1] || 'en').catch(
      console.error
    );

    const params = new URLSearchParams(window.location.search);
    const customer = params.get('customer');
    if (customer) {
      setCustomer(customer);
    }
  }, [user]);

  if (loading) {
    return (
      <div>
        <Row justify="center" align="middle" style={{ height: '100vh' }}>
          <div
            style={{
              textAlign: 'center',
            }}
          >
            <p>
              {' '}
              <Spin />
            </p>
            <p> Loading </p>
          </div>
        </Row>
      </div>
    );
  }

  return (
    <AuthContext.Provider
      value={{ user, setUser, customer, selectedGarage, setSelectedGarage }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => useContext(AuthContext);
