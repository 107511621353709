import { Menu } from 'antd';
import {
  faNewspaper,
  faBullhorn,
  faMessage,
  faSlidersH,
  faListCheck,
} from '@fortawesome/free-solid-svg-icons';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import * as S from './styles';
import { Icon } from '../../atom';
import { useGroupAssetsContext } from '../../../context/groupIdColors';
import { useTranslation } from 'react-i18next';

type MarketingDashboardLayoutProps = {
  title?: string;
  children: React.ReactNode;
};
export const MarketingDashboardLayout = ({
  title,
  children,
}: MarketingDashboardLayoutProps) => {
  const { t } = useTranslation();
  const [collapsed, setCollapsed] = useState(true);

  const { groupAssets } = useGroupAssetsContext();
  const { colors, logo } = groupAssets;

  const router = useHistory();

  const items = [
    {
      key: '3',
      label: t('dashboard.campaigns'),
      path: '/',
      icon: <Icon icon={faNewspaper} color={colors.secondary} />,
    },
    {
      key: '4',
      label: t('dashboard.messages'),
      path: '/notifications',
      icon: <Icon icon={faBullhorn} color={colors.secondary} />,
    },
    {
      key: '5',
      label: t('dashboard.personalMessage'),
      path: '/personal-message',
      disabled: false,
      icon: <Icon icon={faMessage} color={colors.secondary} />,
    },
    {
      key: '6',
      label: t('dashboard.welcomeSlider'),
      path: '/welcome-slider',
      disabled: false,
      icon: <Icon icon={faListCheck} color={colors.secondary} />,
    },
  ];

  return (
    <S.DashboardLayout>
      <S.Sider
        collapsible
        collapsed={collapsed}
        onCollapse={(value) => setCollapsed(value)}
      >
        <S.Menu
          theme="light"
          defaultSelectedKeys={['1']}
          mode="inline"
          primary={colors.primary}
          secondary={colors.secondary}
        >
          <S.Logo src={logo} alt="logo" />
          {items.map((item) => (
            <Menu.Item
              key={item.key}
              icon={item.icon}
              disabled={item.disabled}
              onClick={() => router.push(item.path)}
            >
              {item.label}
            </Menu.Item>
          ))}
        </S.Menu>
      </S.Sider>
      <S.ContentWrapper className="site-layout">
        <S.Header>
          <h2>{t('dashboard.marketingPlatform')}</h2>
          <S.SwitchMenu>
            {/* <img src={'/images/switch.png'} alt="logo" /> */}
          </S.SwitchMenu>
        </S.Header>
        <S.Content>
          {children}
          <S.Footer
            style={{
              textAlign: 'center',
            }}
          >
            <img src={'/images/powered-by.png'} alt="logo" />
          </S.Footer>
        </S.Content>
      </S.ContentWrapper>
    </S.DashboardLayout>
  );
};
