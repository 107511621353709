/**
 * Model NotificationTagLocalization
 *
 */

export const NotificationTagCategory = {
  ACTIVATION: 'ACTIVATION',
  BOOKING: 'BOOKING',
  CAMPAIGNS: 'CAMPAIGNS',
  CP_USER_DATA: 'CP_USER_DATA',
  GARAGE_DATA: 'GARAGE_DATA',
  SERVICES: 'SERVICES',
  VEHICLE_DATA: 'VEHICLE_DATA',
  GROUP_DATA: 'GROUP_DATA',
} as const;

export const domains = {
  services: ['group', 'garage', 'group-beta', 'garage-beta', 'services'],
  marketing: ['marketing-beta', 'marketing'],
  claims: ['claims-beta', 'claims', 'claims-module-beta'],
  dashboard: ['dashboard-beta', 'dashboard'],
} as const;

export interface Campaign {
  id: string;
  position: number;
  title: string;
  subTitle: string;
  content: string;
  image: string;
  callToActionUrl: string;
  callToActionText: string;
  groupId: string;
  publishDate?: Date | string | null;
  startDate?: Date | string | null;
  published?: boolean;
  endDate?: Date | string | null;
  status?: string;
  createdBy: number;
  lastUpdatedBy?: number | null;
  createdAt?: Date | string;
  updatedAt?: Date | string;
}

export type NotificationTags = {
  id: string;
  tag: string;
  category: typeof NotificationTagCategory;
  isPushNotification: boolean;
  isEmail: boolean;
  Localizations?: NotificationTagLocalization[];
};

export type NotificationTagLocalization = {
  id: string;
  languageId: number;
  data: string;
  description: string | null;
  example: string | null;
  NotificationTagId: string;
};

export type NotificaitonMaster = {
  languageId: number;
  Id: number;
  TemplateContent: string;
  TemplateKey: string;
  IsActive: boolean;
  CreatedOn: Date;
  UpdatedOn: Date;
  UpdatedBy: number;
  TemplateTitle: string;
  ConfigureAlertId: string;
  Slug: string;
  NotificaitonMasterId: number | null;
};

export enum NotificationType {
  EMAIL = 'EMAIL',
  PUSH = 'PUSH',
}

export enum PersonalMessageCategory {
  PUBLIC_HOLIDAYS = 'PUBLIC_HOLIDAYS',
  WEATHER = 'WEATHER',
  TRAFFIC = 'TRAFFIC',
  MAINTENANCE = 'MAINTENANCE',
  CURRENT_NEWS = 'CURRENT_NEWS',
  PROMOTION = 'PROMOTION',
}

/**
 * Model NotificationTemplate
 *
 */
export type NotificationTemplate = {
  id: string;
  title: string;
  content: string;
  type: NotificationType;
  strokeMessage: string;
  status: string | null;
  groupId: string | null;
  common: boolean;
  event: string;
  read: boolean;
  createdAt: Date;
  updatedAt: Date;
  tags: string[];
  languageId: number;
};

export type PersonalMessageTemplate = {
  id: string;
  title: string;
  titleEn: string;
  titleNl: string;
  strokeMessageNl: string;
  strokeMessageEn: string;
  category: PersonalMessageCategory;
  startDate: string;
  isScheduled: boolean;
  sendStroke: boolean;
  isTemplate: boolean;
  expiryDate: string;
  groupId: string | null;
  createdAt: Date;
  updatedAt: Date;
};

export type WelcomeSliderTemplate = {
  id: string;
  title: string;
  description: string;
  image: string;
  lists: Array<{ title: string; description?: string; image?: string }>;
  groupId: string | null;
  createdAt: Date;
  updatedAt: Date;
  languageId: number;
};
