import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { useAuthContext } from '../../../context/auth'
import { useGroupAssetsContext } from '../../../context/groupIdColors'
import { Icon } from '../../atom'
import * as S from './styles'

type ClaimLayoutProps = {
  title: string
  children: React.ReactNode
}

export const ClaimsLayout = ({ children, title }: ClaimLayoutProps) => {
  const { groupAssets } = useGroupAssetsContext()
  const domain = window.location.hostname.split('.')[0]
  const router = useHistory()
  const goToPage = (page: string) => {
    setOpen(false)
    router.push(page)
  }
  const [open, setOpen] = React.useState(false)
  const { user } = useAuthContext()

  return (
    <S.Main>
      <S.Header>
        <div>
          <img src={groupAssets.logo} height="30" alt="logo" />
        </div>
        <S.HeaderControl>
          <p>{domain}</p>
          <S.DropDown
            overlay={() => <S.DropdownOption>hello</S.DropdownOption>}
            trigger={['click']}
            disabled
          >
            <div> {user?.groupName} </div>
          </S.DropDown>

          <S.DropDown
            open={open}
            overlay={() => (
              <S.DropdownOption>
                <p onClick={() => goToPage('/')}>Claims</p>
                <p onClick={() => goToPage('/services')}>Services</p>
              </S.DropdownOption>
            )}
            onOpenChange={() => setOpen(!open)}
            trigger={['click']}
          >
            <S.MenuButton
              size="large"
              icon={<img src="/images/menu.png" alt="menu" />}
            />
          </S.DropDown>
        </S.HeaderControl>
      </S.Header>
      <S.SubTitle>
        <h3>
          {' '}
          <Icon
            icon={faArrowLeft}
            size="sm"
            color="#000"
            onClick={() => router.push('/')}
          />{' '}
          {title}
        </h3>
      </S.SubTitle>
      <S.Wrapper>{children}</S.Wrapper>
    </S.Main>
  )
}
