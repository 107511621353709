import axios, { AxiosError, AxiosInstance, AxiosRequestConfig } from 'axios';
import axiosRetry from 'axios-retry';
import { LocalStorage } from '../../../../utils';
import { ISendMessage } from '../types';

interface CustomAxiosRequestConfig extends AxiosRequestConfig {
  noRetry?: boolean;
}

class ChatApiService {
  private apiClient: AxiosInstance;
  private urls: Record<string, string>;

  constructor() {
    this.apiClient = axios.create({
      baseURL: process.env.REACT_APP_BASE_URL,
      withCredentials: false,
      timeout: 120000,
    });

    // Add an interceptor to set authorization header with user token before requests
    this.apiClient.interceptors.request.use(
      (config: CustomAxiosRequestConfig) => {
        const token = LocalStorage.get('token');
        if (token) {
          config.headers = config.headers || {};
          config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    axiosRetry(this.apiClient, {
      retries: 3,
      retryCondition: (error: AxiosError) => {
        const config = error.config as CustomAxiosRequestConfig;

        // Skip retry if noRetry flag is set in the request config
        if (config?.noRetry) {
          return false;
        }

        // Skip retries for specific methods or endpoints
        const skipRetryEndpoints: string[] = [];
        const skipRetryMethods: string[] = [];

        if (
          skipRetryEndpoints.includes(config.url || '') ||
          skipRetryMethods.includes(config.method?.toUpperCase() || '')
        ) {
          return false;
        }

        // Apply retry logic for network or idempotent request errors
        return axiosRetry.isNetworkOrIdempotentRequestError(error);
      },
      retryDelay: (retryCount) => {
        return retryCount * 1000;
      },
    });

    // urls
    this.urls = {
      chats: '/chats',
      message: '/chats/message',
      checkExistingConversation: '/chats/check-conversation',
      markAsRead: '/chats/message/mark-as-read',
      markAsUnRead: '/chats/message/mark-as-unread',
      userProfile: '/users/cp-user-details',
      groupGarages: '/chats/group-garages',
    };
  }

  getUserChats({
    cursor,
    limit,
    type,
    searchQuery,
    isSearchByName,
  }: {
    cursor: string | null;
    limit: number;
    type?: 'pending' | 'all' | (string & {});
    searchQuery?: string;
    isSearchByName?: boolean;
  }) {
    return this.apiClient.get(this.urls.chats, {
      params: this.sanitize({
        cursor,
        limit,
        type,
        isSearchByName,
        searchQuery,
      }),
    });
  }

  fetchAllChatsForGaragesInGroup({
    cursor,
    limit,
    groupId,
    garageId,
    type = 'pending',
    searchQuery,
    isSearchByName,
  }: {
    cursor: string | null;
    limit: number;
    groupId: string;
    garageId?: string;
    type?: 'pending' | 'all' | (string & {});
    searchQuery?: string;
    isSearchByName?: boolean;
  }) {
    return this.apiClient.get(`${this.urls.chats}/${groupId}`, {
      params: this.sanitize({
        cursor,
        limit,
        garageId,
        type,
        isSearchByName,
        searchQuery,
      }),
    });
  }

  getChatMessages({
    conversationId,
    cursor,
    limit,
    direction,
    tagId,
    ...props
  }: {
    conversationId: string;
    cursor: string | null | Date;
    limit: number;
    tagId: string;
    direction?: 'newer' | 'older';
    props?: Record<string, any>;
  }) {
    return this.apiClient.get(`${this.urls.message}/${conversationId}`, {
      params: { cursor, limit, direction, tagId, ...props },
    });
  }

  checkExistingConversation({
    receiverId,
    tagId,
    ...props
  }: {
    receiverId: string;
    tagId: string;
    props?: Record<string, any>;
  }) {
    return this.apiClient.get(
      `${this.urls.checkExistingConversation}/${receiverId}`,
      { params: { ...props, tagId } }
    );
  }

  markMessageAsRead({
    conversationId,
    tagId,
    ...props
  }: {
    conversationId: string;
    tagId: string;
    props?: Record<string, any>;
  }) {
    return this.apiClient.get(`${this.urls.markAsRead}/${conversationId}`, {
      params: { ...props, tagId },
    });
  }

  markMessagesAsUnread({
    conversationId,
    tagId,
    unreadCount,
    ...props
  }: {
    conversationId: string;
    tagId: string;
    unreadCount: number;
    props?: Record<string, any>;
  }) {
    return this.apiClient.get(`${this.urls.markAsUnRead}/${conversationId}`, {
      params: { ...props, tagId, unreadCount },
    });
  }

  sendMessage({
    data,
    ...props
  }: {
    data: ISendMessage;
    props?: Record<string, any>;
  }) {
    return this.apiClient.post(
      `${this.urls.message}/${data.conversationId}`,
      data,
      { params: { ...props } }
    );
  }

  getUserProfile(licensePlate: string, noRetry = false) {
    return this.apiClient.get(`${this.urls.userProfile}`, {
      params: { licensePlate },
      noRetry,
    } as CustomAxiosRequestConfig);
  }

  getGroupGarages({ cursor, limit, searchQuery }) {
    return this.apiClient.get(`${this.urls.groupGarages}`, {
      params: this.sanitize({ cursor, limit, searchQuery }),
    });
  }

  private sanitize = (query: Record<string, any>) => {
    const cleanQuery = {};
    Object.keys(query).forEach((key) => {
      if (
        query[key] !== undefined &&
        query[key] !== null &&
        query[key] !== ''
      ) {
        cleanQuery[key] = query[key];
      }
    });
    return cleanQuery;
  };
}

// Export an instance of the class
const chatApiService = new ChatApiService();
export default chatApiService;
