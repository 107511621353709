import { Col, Form, FormInstance, message, Row } from 'antd';
import React, { useCallback, useEffect } from 'react';
import { isEmpty } from 'lodash';

import { useTranslation } from 'react-i18next';
import i18n from 'i18next';

import { LANGUAGES } from '../../../locales';
import { Button, Icon, Input, Tabs } from '../../atom';
import fetchHook from '../../../constants/useFetchHook';
import { NotificationTags } from '../../../types';
import { useAuthContext } from '../../../context/auth';
import { TabContent } from './notification-tab-content';
import * as S from './styles';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { LanguageId } from '../../../domains/marketing/notifications/id';
import { ButtonText } from '../../atom/buttonText';
import { useHistory } from 'react-router-dom';

export const NotificationForm = ({
  form: { nlForm, enForm },
  formValues,
  setFormValues,
  submitForm: _submitForm,
  resetForm,
  setActiveLanguage,
}: {
  form: { enForm: FormInstance; nlForm: FormInstance };
  formValues?: any;
  setFormValues: any;
  submitForm: <T>(id: T) => void;
  resetForm: () => void;
  setActiveLanguage?: (language: number) => void;
}) => {
  const { t } = useTranslation();
  const { user } = useAuthContext();
  const history = useHistory();

  const [tags, setTags] = React.useState<NotificationTags[]>([]);
  const [loading, setLoading] = React.useState<boolean>(false);

  const getNotificationTags = useCallback(async () => {
    setLoading(true);
    const { response, error } = await fetchHook({
      url: '/api/notification-tags/' + user.languageId,
      method: 'GET',
    });
    const tags: NotificationTags[] = response?.data?.tags || [];
    tags.sort((a, b) => (a.tag > b.tag ? 1 : a.tag < b.tag ? -1 : 0));

    setTags(response?.data?.tags || []);
    setLoading(false);
  }, [user.languageId]);

  useEffect(() => {
    getNotificationTags();
  }, [getNotificationTags]);

  const submitForm = async (values, id: 'en' | 'nl') => {
    const d = {
      ...formValues,
      [id]: values,
    };
    setFormValues(d);
    if (isEmpty(d.en) || isEmpty(d.nl)) {
      message.error(t('dashboard.fillAllFields'));
      return;
    }
    console.log('all submitting values', d);
    await _submitForm(d);
  };

  const goToPage = (pageId: string) => {
    history.push(`/notifications/${pageId}`);
  };

  const nlFormItem = {
    label: LANGUAGES(t)[0].label as unknown as string,
    key: LANGUAGES(t)[0].value,
    children: (
      <Form
        layout="vertical"
        onFinish={(values) => submitForm(values, 'nl')}
        initialValues={{
          ...(formValues?.nl || {}),
          subject: formValues?.nl?.title,
        }}
      >
        <Row gutter={[32, 32]}>
          <Col span={16}>
            <PageNavigationButton
              goToPage={goToPage}
              prevTemplateEvent={formValues.nl.prevNotificationEvent}
              nextTemplateEvent={formValues.nl.nextNotificationEvent}
            />

            <TabContent
              initialData={formValues?.nl}
              tags={tags}
              form={nlForm}
              id="content2"
            />
          </Col>
          <Col span={8} style={{ marginTop: 29 }}>
            <S.FormWrapper>
              <Form.Item label={t('notification.template')} name="title">
                <Input
                  placeholder={t('notification.template')}
                  id="title"
                  disabled
                />
              </Form.Item>
              <S.ButtonWrapper>
                <Button $variant="tertiary" htmlType="submit">
                  {t('dashboard.save')}
                </Button>
              </S.ButtonWrapper>

              <S.RemovePostButton htmlType="button" onClick={resetForm}>
                <Icon icon={faTrash} strokeWidth="1" />{' '}
                {t('notifications.reset')}
              </S.RemovePostButton>
            </S.FormWrapper>
          </Col>
        </Row>
      </Form>
    ),
  };
  const enFormItem = {
    label: LANGUAGES(t)[1].label as unknown as string,
    key: LANGUAGES(t)[1].value,
    children: (
      <Form
        layout="vertical"
        onFinish={(values) => submitForm(values, 'en')}
        initialValues={{
          ...(formValues?.en || {}),
          subject: formValues?.en?.title,
        }}
      >
        <Row gutter={[32, 32]}>
          <Col span={16}>
            <PageNavigationButton
              goToPage={goToPage}
              prevTemplateEvent={formValues.en.prevNotificationEvent}
              nextTemplateEvent={formValues.en.nextNotificationEvent}
            />

            <TabContent
              initialData={formValues?.en}
              tags={tags}
              form={enForm}
              id="content1"
            />
          </Col>
          <Col span={8} style={{ marginTop: 29 }}>
            <S.FormWrapper>
              <Form.Item label={t('notification.template')} name="title">
                <Input
                  placeholder={t('notification.template')}
                  id="title"
                  disabled
                />
              </Form.Item>
              <S.ButtonWrapper>
                <Button $variant="tertiary" htmlType="submit">
                  {t('dashboard.save')}
                </Button>
              </S.ButtonWrapper>

              <S.RemovePostButton htmlType="reset" onClick={resetForm}>
                <Icon icon={faTrash} strokeWidth="1" />{' '}
                {t('notifications.reset')}
              </S.RemovePostButton>
            </S.FormWrapper>
          </Col>
        </Row>
      </Form>
    ),
  };

  let items = [enFormItem, nlFormItem];
  if (user.languageId === LanguageId.NETHERLANDS) {
    items = [nlFormItem, enFormItem];
  }

  return (
    <>
      <Tabs
        onChange={(languageValue) =>
          setActiveLanguage(
            languageValue === 'en' ? LanguageId.ENGLISH : LanguageId.NETHERLANDS
          )
        }
        items={items}
      />
    </>
  );
};

const PageNavigationButton = ({
  prevTemplateEvent,
  nextTemplateEvent,
  goToPage,
}) => {
  return (
    <Row
      justify="end"
      style={{
        justifyContent: 'flex-end',
        marginBottom: 5,
      }}
    >
      <ButtonText
        arrowDirection="prev"
        onClick={() => goToPage(prevTemplateEvent)}
        label={i18n.t('general.previous')}
        disabled={!prevTemplateEvent}
      />

      <ButtonText
        arrowDirection="next"
        onClick={() => goToPage(nextTemplateEvent)}
        label={i18n.t('general.next')}
        disabled={!nextTemplateEvent}
      />
    </Row>
  );
};
