import React, { useCallback, useEffect, useState } from 'react';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { Col, Divider, Form, message, notification, Row, Spin } from 'antd';
import {
  Button,
  MarketingDashboardLayout,
  Gap,
  Icon,
  Text,
} from '../../../components';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import fetchHook from '../../../constants/useFetchHook';
import { useAuthContext } from '../../../context/auth';
import { useTranslation } from 'react-i18next';
import { NotificationForm } from '../../../components/molecules/campaigns/notification-form';
import { useGroupAssetsContext } from '../../../context/groupIdColors';
import { NotificationsMap } from '../../../components/molecules/campaigns/notification-tab-content';
import edjsParser from 'editorjs-parser';
import { getSpecificInstance } from '../../../locales';

export enum Status {
  'DRAFT' = 'draft',
  'PUBLISHED' = 'published',
  'SCHEDULED' = 'scheduled',
}

export const LanguageId = {
  ENGLISH: 1,
  NETHERLANDS: 2,
};

export default function EditNotification() {
  const { user } = useAuthContext();
  // TODO: add type to all forms
  const [enForm] = Form.useForm();
  const [nlForm] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const { t } = useTranslation();
  const { groupAssets } = useGroupAssetsContext();

  const [language, setLanguage] = useState(
    user.languageId === LanguageId.NETHERLANDS
      ? LanguageId.NETHERLANDS
      : LanguageId.ENGLISH
  );

  const params = useParams<{ id: string; adminAction?: string }>();

  const resetForm = () => {
    const form = language === LanguageId.NETHERLANDS ? nlForm : enForm;
    form.resetFields();
  };

  const [formValues, setFormValues] = useState({
    en: {},
    nl: {},
  });

  const query = new URLSearchParams(useLocation().search).toString();

  const submitForm = async (values) => {
    // if the params contain  a code names admin-action add the  request url
    // eslint-disable-next-line react-hooks/rules-of-hooks

    const url = '/notification/template/' + params.id + '?' + query;
    const { response, error } = await fetchHook({
      url,
      method: 'POST',
      body: values,
    });

    if (error) {
      message.error(t('dashboard.somethingWentWrong'));
      return;
    }
    message.success(t('dashboard.success'));
    // router.push(response.data.Id)
  };

  const getNotificationDetails = useCallback(async () => {
    setLoading(true);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { response, error } = await fetchHook({
      url: `/notification/template/${params.id}`,
      method: 'get',
    });
    console.log(response, 'notification details');
    if (!error) {
      setFormValues({
        nl:
          response.data?.find(
            (item) => item.languageId === LanguageId.NETHERLANDS
          ) || {},
        en:
          response.data?.find(
            (item) => item.languageId === LanguageId.ENGLISH
          ) || {},
      });
    }
    setLoading(false);
  }, [params.id]);

  useEffect(() => {
    getNotificationDetails();
  }, [getNotificationDetails]);

  const previewNotification = async () => {
    const formValue =
      language === LanguageId.NETHERLANDS ? formValues['nl'] : formValues['en'];
    const _t =
      (await language) === LanguageId.NETHERLANDS
        ? await getSpecificInstance('nl')
        : await getSpecificInstance('en');

    notification.open({
      message: formValue['title'],
      // description: formValue['strokeMessage'],
      description: getNotificationPreviewContent(formValue, _t),
      placement: 'top',
      closeIcon: null,
      duration: 0,
      icon: (
        <div>
          <img src={groupAssets.logo} alt="" width={'35'} />
        </div>
      ),
    });
  };

  const parser = new edjsParser();

  const getNotificationPreviewContent = (formValue, t) => {
    const buttonText = formValue['url']
      ? NotificationsMap(t)[formValue['url']]
      : formValue['callToActionText'] || '';
    const html =
      (formValue['content'] &&
        parser.parse(JSON.parse(formValue['content']))) ||
      '';
    return (
      <Col>
        <Row align="middle">
          <Text style={{ fontSize: 14, paddingTop: 8, fontWeight: 400 }}>
            {formValue['strokeMessage']}
          </Text>
        </Row>
        <Divider style={{ margin: 12 }} />
        <Col>
          <div dangerouslySetInnerHTML={{ __html: html }} />
          <Button $variant="secondary">{buttonText}</Button>
          <Text style={{ fontSize: 14, paddingTop: 8, fontWeight: 400 }}>
            {formValue['signature']}
          </Text>
        </Col>
      </Col>
    );
  };

  return (
    <MarketingDashboardLayout>
      <Row align="middle" justify="space-between">
        <Col>
          <Text level={4} onClick={() => history.goBack()} cursor>
            <Icon icon={faArrowLeft} />
            <span
              style={{
                marginLeft: '20px',
              }}
            >
              {' '}
              {t('notification.title')}{' '}
            </span>
          </Text>
        </Col>
        <Col>
          <Button
            onClick={async () => {
              const [en, nl] = await Promise.all([
                enForm.validateFields(),
                nlForm.validateFields(),
              ]);
              if (en && nl) {
                previewNotification();
              }
            }}
          >
            {t('dashboard.preview')}
          </Button>
        </Col>
      </Row>
      <Gap gap={20} />

      {loading && (
        <Row justify="center" align="middle" style={{ height: '70vh' }}>
          <Spin size="large" />
        </Row>
      )}

      {!loading && (
        <NotificationForm
          form={{ enForm, nlForm }}
          setFormValues={setFormValues}
          formValues={formValues}
          submitForm={submitForm}
          resetForm={resetForm}
          setActiveLanguage={(language) => setLanguage(language)}
        />
      )}
    </MarketingDashboardLayout>
  );
}
