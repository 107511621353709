export type Clients = {
  name: string;
  groupName: string;
  logo: string;
  theme: {
    light: {
      primary: string;
      secondary: string;
      accent: string;
    };
    dark: {
      primary: string;
      secondary: string;
      accent: string;
    };
  };
  font: {
    medium: string;
    bold: string;
    extraBold: string;
  };
  appUrl: {
    ios: string;
    android: string;
  };
};

const igarage: Clients = {
  name: 'igarage',
  groupName: '',
  logo: '/images/igarage.png',
  theme: {
    light: {
      primary: '#2B2F44',
      secondary: '#ffffff',
      accent: '#2B2F44',
    },
    dark: {
      primary: '#2B2F44',
      secondary: '#ffffff',
      accent: '#2B2F44',
    },
  },
  font: {
    medium: 'Manrope-Medium',
    bold: 'Manrope-Bold',
    extraBold: 'Manrope-ExtraBold',
  },
  appUrl: {
    ios: 'https://apps.apple.com/us/app/igarage-car-passport/id1117766263',
    android:
      'https://play.google.com/store/apps/details?id=com.igarage.uk.production',
  },
};
const vakgarage: Clients = {
  name: 'vakgarage',
  groupName: 'VAK',
  logo: '/images/vackgarage.jpeg',
  theme: {
    light: {
      primary: '#333034',
      secondary: '#ffffff',
      accent: '#E2593A',
    },
    dark: {
      primary: '#333034',
      secondary: '#ffffff',
      accent: '#E2593A',
    },
  },
  font: {
    medium: 'SansaConStd-Normal',
    bold: 'SansaConStd-SemiBold',
    extraBold: 'SansaConStd-Bold',
  },
  appUrl: {
    ios: 'https://apps.apple.com/us/app/vakgarage-app/id1387786827',
    android: 'https://play.google.com/store/apps/details?id=com.vak.cp',
  },
};
const grange_maintained: Clients = {
  name: 'grange_maintained',
  groupName: 'GMU',
  logo: '/images/grange_maintained.jpeg',
  font: {
    medium: 'OpenSans',
    bold: 'OpenSans-Bold',
    extraBold: 'OpenSans-Extrabold',
  },
  theme: {
    light: {
      primary: '#2C5C4C',
      secondary: '#ffffff',
      accent: '#2C5C4C',
    },
    dark: {
      primary: '#2C5C4C',
      secondary: '#ffffff',
      accent: '#2C5C4C',
    },
  },
  appUrl: {
    ios: 'https://apps.apple.com/us/app/grange-maintained/id1582966784',
    android:
      'https://play.google.com/store/apps/details?id=com.garage.maintained.uk.production',
  },
};
const auto_koese: Clients = {
  name: 'auto_koese',
  groupName: 'AKO',
  logo: '/images/auto_koese.png',
  font: {
    medium: 'OpenSans',
    bold: 'OpenSans-Bold',
    extraBold: 'OpenSans-Extrabold',
  },
  theme: {
    light: {
      primary: '#fcb434',
      secondary: '#ffffff',
      accent: '#fcb434',
    },
    dark: {
      primary: '#fcb434',
      secondary: '#ffffff',
      accent: '#fcb434',
    },
  },
  appUrl: {
    ios: 'https://apps.apple.com/us/app/auto-koese/id1569583321',
    android:
      'https://play.google.com/store/apps/details?id=com.autokoese.production',
  },
};
const autobandenpartner: Clients = {
  name: 'autobandenpartner',
  logo: '/images/grip-logo.png',
  groupName: 'ABC',

  theme: {
    light: {
      primary: '#333034',
      secondary: '#ffffff',
      accent: '#E2593A',
    },
    dark: {
      primary: '#333034',
      secondary: '#ffffff',
      accent: '#E2593A',
    },
  },
  font: {
    medium: 'Manrope-Medium',
    bold: 'Manrope-Bold',
    extraBold: 'Manrope-ExtraBold',
  },
  appUrl: {
    ios: 'https://apps.apple.com/us/app/grip-expert/id6457160198',
    android: 'https://play.google.com/store/apps/details?id=com.grip.cp',
  },
};
const grip_expert: Clients = {
  name: 'grip_expert',
  logo: '/images/grip-logo.png',
  groupName: 'GRIP',

  theme: {
    light: {
      primary: '#333034',
      secondary: '#ffffff',
      accent: '#E2593A',
    },
    dark: {
      primary: '#333034',
      secondary: '#ffffff',
      accent: '#E2593A',
    },
  },
  font: {
    medium: 'Manrope-Medium',
    bold: 'Manrope-Bold',
    extraBold: 'Manrope-ExtraBold',
  },
  appUrl: {
    ios: 'https://apps.apple.com/us/app/grip-expert/id6457160198',
    android: 'https://play.google.com/store/apps/details?id=com.grip.cp',
  },
};

export default {
  igarage,
  vakgarage,
  grange_maintained,
  auto_koese,
  autobandenpartner,
  'GRIP Expert': grip_expert,
} as const;
