import styled, { css, FlattenSimpleInterpolation } from 'styled-components';
import { theme } from '../../theme';

const { breakpoints } = theme;

interface ResponsiveWrapperProps {
  hideOnMobile?: boolean;
  hideOnTablet?: boolean;
  hideOnDesktop?: boolean;
  baseStyles?: FlattenSimpleInterpolation;
  mobileStyles?: FlattenSimpleInterpolation;
  tabletStyles?: FlattenSimpleInterpolation;
  desktopStyles?: FlattenSimpleInterpolation;
}

const visibilityStyles = css<ResponsiveWrapperProps>`
  ${({ baseStyles }) => baseStyles}

  @media (max-width: ${breakpoints.mobile}) {
    ${({ mobileStyles }) => mobileStyles}
    ${({ hideOnMobile }) =>
      hideOnMobile &&
      css`
        display: none;
      `}
  }

  @media (min-width: ${breakpoints.mobile}) and (max-width: ${breakpoints.tablet}) {
    ${({ tabletStyles }) => tabletStyles}
    ${({ hideOnTablet }) =>
      hideOnTablet &&
      css`
        display: none;
      `}
  }

  @media (min-width: ${breakpoints.desktop}) {
    ${({ desktopStyles }) => desktopStyles}
    ${({ hideOnDesktop }) =>
      hideOnDesktop &&
      css`
        display: none;
      `}
  }
`;

export const ResponsiveWrapper = styled.div<ResponsiveWrapperProps>`
  ${visibilityStyles}
`;
