import { useState } from 'react';
import { uploadFile } from 'react-s3';
import { message } from 'antd';
import { useS3Upload } from 'next-s3-upload';
import { useTranslation } from 'react-i18next';
import imageCompression from 'browser-image-compression';

const S3_BUCKET = process.env.REACT_APP_S3_UPLOAD_BUCKET;
const REGION = process.env.REACT_APP_S3_UPLOAD_REGION;
const ACCESS_KEY = process.env.REACT_APP_S3_UPLOAD_KEY;
const SECRET_ACCESS_KEY = process.env.REACT_APP_S3_UPLOAD_SECRET;

const config = {
  bucketName: S3_BUCKET,
  region: REGION,
  accessKeyId: ACCESS_KEY,
  secretAccessKey: SECRET_ACCESS_KEY,
  dirName: 'images' /* optional */,
};

export const useFileUpload = (handleChange: (url: string) => void) => {
  const { t } = useTranslation();
  window.Buffer = window.Buffer || require('buffer').Buffer;
  const { FileInput, openFileDialog } = useS3Upload({});
  const [loading, setLoading] = useState(false);
  const [preview, setPreview] = useState<string | null>(null);

  const handleFileUpload = async (file?: File) => {
    if (!file) return;

    const previewURL = URL.createObjectURL(file);
    setPreview(previewURL);

    setLoading(true);

    try {
      if (file.type.startsWith('image')) {
        const options = {
          maxSizeMB: 1,
          maxWidthOrHeight: 800,
          useWebWorker: true,
        };

        const compressedFile = await imageCompression(file, options);

        file = compressedFile;
      } else {
        console.warn('Skipping compression: File is not an image');
      }

      const response = await uploadFile(file, config);
      handleChange(response.location);

      message.success(t('message.imageUploadSuccessful'));
    } catch (error) {
      message.error(t('message.imageUploadFailed'));
    } finally {
      setLoading(false);
    }
  };

  return {
    FileInput,
    openFileDialog,
    loading,
    handleFileUpload,
    preview,
  };
};
