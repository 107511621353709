import { TagProps as AntTagProps, Tag as AntTag } from 'antd'
import styled from 'styled-components'
import { colors } from '../constants'
import { UITheme } from '../theme'

enum TagVariant {
  primary = 'primary',
  secondary = 'secondary',
  tertiary = 'tertiary',
  quaternary = 'quaternary',
}

export const getTagVariantColor = (theme: UITheme) => ({
  [TagVariant.primary]: {
    color: theme.groupID?.primary,
    background: colors.white,
  },
  [TagVariant.secondary]: {
    color: colors.grey,
    background: colors.white,
  },
  [TagVariant.tertiary]: {
    color: colors.green,
    background: colors.white,
  },
  [TagVariant.quaternary]: {
    color: colors.white,
    background: theme.groupID?.primary,
  },

})

type TagProps = AntTagProps & {
  $variant?: keyof typeof TagVariant
  textColor?: string
}

export const Tag = (props: TagProps) => {
  return <StyledTag {...props} />
}

const StyledTag = styled(AntTag)<TagProps>`
    &&&{
        border-radius: 16px ;
        color:  ${({ theme, textColor }) =>
          textColor || theme.colors.black} !important;
        outline: none;
        min-width: 79px;
        text-align: center;
        font-size: 12px;
        text-transform: capitalize;

        &:hover{
            /* color: ${({ $variant = 'primary', theme }) =>
              getTagVariantColor(theme)[$variant].color}; */
        }

        &:disabled{
            background-color: ${({ theme }) => theme.colors.grey50};
            cursor: not-allowed;
        }
    }
`
