import styled from 'styled-components';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { colors } from '../constants';
import { Icon } from './icon';

const Button = styled.button`
  background: none;
  border: none;
  color: ${({ color }) => (color ? color : 'inherit')};
  font: inherit;
  font-size: 14px;
  font-weight: 600;
  text-decoration: underline;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};

  &:hover {
    background: ${({ disabled }) => (disabled ? 'none' : '#f0f0f0')};
  }
`;

export const ButtonText = (props: {
  onClick: () => void;
  label: string;
  disabled?: boolean;
  hideButton?: boolean;
  color?: string;
  arrowDirection: 'prev' | 'next';
}) => {
  const { onClick, label, disabled, hideButton, color, arrowDirection } = props;

  if (hideButton) return null;

  const arrowIcon = (
    <Icon
      icon={arrowDirection === 'next' ? faArrowRight : faArrowLeft}
      color={colors.secondary}
    />
  );

  return (
    <Button onClick={onClick} disabled={disabled} color={color}>
      {arrowDirection === 'prev' && arrowIcon}
      <span>{label}</span>
      {arrowDirection === 'next' && arrowIcon}
    </Button>
  );
};
