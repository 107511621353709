import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { Checkbox, Form, Modal, TimePicker } from 'antd';
import moment from 'moment';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DATE_FORMAT, TIME_FORMAT } from '../../../constants';
import { Status } from '../../../domains/marketing/news-campaigns';
import { Icon, DatePicker, Select, Option, Button, Text } from '../../atom';
import * as S from './styles';
import { useAuthContext } from '../../../context/auth';
import { LanguageId } from '../../../domains/marketing/notifications/id';

export interface PublishFormProps {
  onPreview: (value: boolean) => void;
  handleSubmit: <T>(values: T) => void;
  deleteItem: () => void;
  initialValues?: Record<string, string>;
}

export const PublishForm = ({
  onPreview,
  handleSubmit,
  deleteItem,
  initialValues: _initialValues,
}: PublishFormProps) => {
  const { t } = useTranslation();
  const [showEndDate, setShowEndDate] = useState(
    _initialValues?.endDate ? true : false
  );
  const { user } = useAuthContext();

  const [form] = Form.useForm();

  let currentDate = new Date();

  currentDate.setMinutes(currentDate.getMinutes() + 30);

  form.setFieldsValue({
    ..._initialValues,
    status: form.getFieldValue('status') || _initialValues?.status || 'draft',
    publishDate:
      form.getFieldValue('publishDate') ||
      _initialValues?.publishDate ||
      moment(currentDate),
  });

  // if (!_initialValues) form.setFieldValue('publishDate', moment());

  const { confirm } = Modal;

  const showConfirm = () => {
    confirm({
      title: t('personalMessage.deleteTitle'),
      content: t('personalMessage.confirmDelete'),
      cancelText: t('personalMessage.cancelText'),
      okText: t('personalMessage.okText'),
      onOk() {
        deleteItem();
      },
    });
  };

  const validatePublishDate = async (_, value) => {
    const currentDateTime = moment();
    const selectedDateTime = moment(value);

    // Check if the selected time is at least 15 minutes greater than the current time
    if (selectedDateTime.isBefore(currentDateTime.add(15, 'minutes'))) {
      return Promise.reject(t('personalMessage.scheduleTimeValidation'));
    }

    return Promise.resolve();
  };

  return (
    <S.FormWrapper>
      <Text level={4}>{t('status.publish')}</Text>
      <Form layout="vertical" form={form}>
        <Form.Item label={t('dashboard.status')} name="status">
          <div>
            <Select
              id="status"
              width="100%"
              defaultValue={form.getFieldValue('status')}
              onChange={(value) => {
                // if (value !== Status.PUBLISHED) {
                //   form.setFieldsValue({ endDate: '' });
                //   form.setFieldsValue({ publishDate: '' });
                // }
                form.setFieldsValue({ status: value });
              }}
            >
              <Option value="draft">{t('status.draft')}</Option>
              <Option value="archived">{t('status.archived')}</Option>
              <Option value="published">{t('status.publish')}</Option>
            </Select>
          </div>
        </Form.Item>
        <Form.Item
          label={t('dashboard.publishDate')}
          name="publishDate"
          rules={[
            // ({}) => ({
            //   validator(rule, value) {
            //     if (form.getFieldValue('status') === 'published' && !value) {
            //       return Promise.reject('Publish date is required');
            //     }
            //     return Promise.resolve();
            //   },
            // }),
            {
              required: true,
              message: 'Publish date is required',
            },
            {
              validator: validatePublishDate,
            },
          ]}
        >
          <div
            style={{
              display: 'flex',
              gap: 5,
            }}
          >
            <DatePicker
              style={{ width: '100%', flex: 2 }}
              placeholder="Select Date"
              format={
                user?.languageId === LanguageId?.ENGLISH
                  ? 'YYYY-MM-DD'
                  : 'DD-MM-YYYY'
              }
              disabledDate={(d) => {
                const current = moment(new Date());
                return current >= moment(d).endOf('day');
              }}
              defaultValue={
                _initialValues?.publishDate
                  ? moment(_initialValues?.publishDate)
                  : moment(currentDate)
              }
              showToday
              onChange={(date) => {
                const currentPublishDate = moment(
                  form.getFieldValue('publishDate')
                );

                const newPublishDate = date
                  ? moment(date).set({
                      hour: currentPublishDate.hour(),
                      minute: currentPublishDate.minute(),
                    })
                  : null;
                form.setFieldsValue({ publishDate: newPublishDate });
              }}
            />
            <TimePicker
              style={{ width: '100%', borderRadius: 10, flex: 1 }}
              placeholder="Select Time"
              format={TIME_FORMAT}
              defaultValue={
                _initialValues?.publishDate
                  ? moment(_initialValues?.publishDate)
                  : moment(currentDate)
              }
              showNow
              onChange={(time) => {
                const currentPublishDate = moment(
                  form.getFieldValue('publishDate')
                );

                const newPublishDate = time
                  ? moment(currentPublishDate).set({
                      hour: time.hour(),
                      minute: time.minute(),
                    })
                  : null;
                form.setFieldsValue({ publishDate: newPublishDate });
              }}
            />
          </div>
        </Form.Item>
        {showEndDate && (
          <Form.Item
            label={t('dashboard.endDate')}
            name="endDate"
            required
            rules={[
              {
                required: true,
                message: 'Please input end date',
              },
            ]}
          >
            <div
              style={{
                display: 'flex',
                gap: 5,
              }}
            >
              <DatePicker
                style={{ width: '100%', flex: 2 }}
                placeholder="Select Date"
                format={
                  user?.languageId === LanguageId?.ENGLISH
                    ? 'YYYY-MM-DD'
                    : 'DD-MM-YYYY'
                }
                disabledDate={(d) => {
                  const current = moment(new Date());
                  return current >= moment(d).endOf('day');
                }}
                defaultValue={
                  _initialValues?.endDate
                    ? moment(_initialValues?.endDate)
                    : undefined
                }
                onChange={(date) => {
                  const currentendDate = moment(form.getFieldValue('endDate'));

                  const newendDate = date
                    ? moment(date).set({
                        hour: currentendDate.hour(),
                        minute: currentendDate.minute(),
                      })
                    : null;
                  form.setFieldsValue({ endDate: newendDate });
                }}
              />
              <TimePicker
                style={{ width: '100%', borderRadius: 10, flex: 1 }}
                placeholder="Select Time"
                format={TIME_FORMAT}
                defaultValue={
                  _initialValues?.endDate
                    ? moment(_initialValues?.endDate)
                    : undefined
                }
                onChange={(time) => {
                  const currentendDate = moment(form.getFieldValue('endDate'));

                  const newendDate = time
                    ? moment(currentendDate).set({
                        hour: time.hour(),
                        minute: time.minute(),
                      })
                    : null;
                  form.setFieldsValue({ endDate: newendDate });
                }}
              />
            </div>
          </Form.Item>
        )}
        <Form.Item>
          <Checkbox
            onChange={(event) => {
              setShowEndDate(event.target.checked);
              !event.target.checked && form.setFieldValue('endDate', undefined);
            }}
            defaultChecked={showEndDate}
          >
            {t('dashboard.addEndDate')}
          </Checkbox>
        </Form.Item>

        {/* send notification and email to active and inactive users */}
        <Text level={5}>{t('newCampaign.notification')}</Text>
        <div
          style={{
            marginBottom: 30,
            display: 'flex',
            flexDirection: 'column',
            gap: 15,
            marginTop: 15,
          }}
        >
          <Form.Item name={'sendNotification'} style={{ margin: 0 }}>
            <Checkbox
              name="sendNotification"
              id="sendNotification"
              defaultChecked={Boolean(_initialValues?.sendNotification)}
              onChange={(event) => {
                console.log(event.target.checked);
                form.setFieldValue('sendNotification', event.target.checked);
              }}
            >
              {t('newCampaign.sendNotification')}
            </Checkbox>
          </Form.Item>

          <Form.Item name={'sendEmailToActiveUsers'} style={{ margin: 0 }}>
            <Checkbox
              name="sendEmailToActiveUsers"
              id="sendEmailToActiveUsers"
              defaultChecked={Boolean(_initialValues?.sendEmailToActiveUsers)}
              onChange={(event) => {
                console.log('sendEmailToActiveUsers', event.target.checked);
                form.setFieldValue(
                  'sendEmailToActiveUsers',
                  event.target.checked
                );
              }}
            >
              {t('newCampaign.sendEmailToActiveUsers')}
            </Checkbox>
          </Form.Item>

          <Form.Item name={'sendEmailToInActiveUsers'} style={{ margin: 0 }}>
            <Checkbox
              name="sendEmailToInActiveUsers"
              id="sendEmailToInActiveUsers"
              defaultChecked={Boolean(_initialValues?.sendEmailToInActiveUsers)}
              onChange={(event) => {
                console.log('sendEmailToInActiveUsers', event.target.checked);
                form.setFieldValue(
                  'sendEmailToInActiveUsers',
                  event.target.checked
                );
              }}
            >
              {t('newCampaign.sendEmailToInActiveUsers')}
            </Checkbox>
          </Form.Item>
        </div>
        <S.ButtonWrapper>
          <Button
            $variant="tertiary"
            onClick={() =>
              form.validateFields().then(() => {
                handleSubmit(form.getFieldsValue());
              })
            }
          >
            {t('dashboard.save')}
          </Button>
        </S.ButtonWrapper>
      </Form>

      <S.RemovePostButton htmlType="button" onClick={showConfirm}>
        <Icon icon={faTrash} strokeWidth="1" /> {t('dashboard.removePost')}
      </S.RemovePostButton>
    </S.FormWrapper>
  );
};
