import { DatePicker as AntDatePicker, DatePickerProps } from 'antd'
import React from 'react'
import styled from 'styled-components'

type StyledDatePickerProps = DatePickerProps & {
    width?: string
}

export const DatePicker = (props: StyledDatePickerProps) => {
    return (
        <StyledDatePicker {...props} />
    )
}


const StyledDatePicker = styled(AntDatePicker) <StyledDatePickerProps>`
     &&&{
        border-radius: 10px ;
        outline: none;
        height: 40px;
        width: ${({ width }) => width || '130px'};
    }
`