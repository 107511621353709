import { Button, Layout, Menu as AntMenu } from 'antd'
import styled from 'styled-components'
import { colors } from '../../constants'

export const DashboardLayout = styled(Layout)`
  height: 100vh;
  overflow: scroll;
`

export const Sider = styled(Layout.Sider)`
  &&& {
    & ul {
      height: 100vh;
    }
    background: ${({ theme }) => theme.groupID.primary};
    & ul,
    & .ant-layout-sider-trigger {
      background: ${({ theme }) => theme.groupID.primary};
    }
    &&& .ant-menu-item-selected {
      background: ${({ theme }) => theme.groupID.primary};
      border-left: 2px solid ${({ theme }) => theme.groupID.green};
    }
    &&& .ant-menu-item:hover {
      background: ${({ theme }) => theme.groupID.secondary};
    }
  }
`

export const Logo = styled.img`
  width: auto;
  height: 32px;
  margin: 16px auto;
  text-align: center;
  display: block;
`

export const Header = styled(Layout.Header)`
  &&& {
    background: ${colors.white};
    /* mild shadow */
    height: 82px;
    box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: auto;
    & h2 {
      margin: 0;
    }
    /* & .ant-layout-header{ */
    padding: 0 32px;
    /* } */
  }
`

export const SwitchMenu = styled(Button)`
  &&& {
    border: none;
    outline: none;
  }
`

export const Content = styled(Layout.Content)`
  &&& {
    overflow: scroll;
    background: #ebedf8;
    padding: 52px 32px;
  }
`

export const ContentWrapper = styled(Layout)`
  overflow: scroll;
`

export const Footer = styled.footer`
  &&& {
    margin-top: 40px;
  }
`

export const Menu = styled(AntMenu)<{
  primary: string
  secondary: string
}>`
  &&& {
    background: ${({ primary }) => primary};
    color: ${({ secondary }) => secondary};
    &&& .ant-menu-item-disabled,
    .ant-menu-submenu-disabled {
      color: ${({ secondary }) => secondary} !important;
    }
    overflow: hidden;
    &&& .ant-menu-item:hover {
      svg {
        color: ${({ primary }) => primary} !important;
      }
      background: ${({ secondary }) => secondary};
      color: ${({ primary }) => primary} !important;
    }
  }
`
