import React from 'react';

export type Routes = {
  path: string;
  exact?: boolean;
  component: React.LazyExoticComponent<React.ComponentType<any>>;
};

const marketing: Routes[] = [
  {
    path: '/',
    exact: true,
    component: React.lazy(() => import('./marketing/index')),
  },
  {
    path: '/news-campaigns/:id',
    exact: true,
    component: React.lazy(() => import('./marketing/news-campaigns/id')),
  },
  {
    path: '/news-campaigns',
    exact: true,
    component: React.lazy(() => import('./marketing/news-campaigns')),
  },
  {
    path: '/notifications/new',
    exact: true,
    component: React.lazy(() => import('./marketing/notifications/new')),
  },
  {
    path: '/notifications/:id',
    exact: true,
    component: React.lazy(() => import('./marketing/notifications/id')),
  },
  {
    path: '/notifications',
    exact: true,
    component: React.lazy(() => import('./marketing/notifications')),
  },
  {
    path: '/personal-message/create/new',
    exact: true,
    component: React.lazy(() => import('./marketing/personal-message/new')),
  },
  {
    path: '/personal-message/templates/all',
    exact: true,
    component: React.lazy(
      () => import('./marketing/personal-message/templates')
    ),
  },
  {
    path: '/personal-message',
    exact: true,
    component: React.lazy(() => import('./marketing/personal-message')),
  },
  {
    path: '/personal-message/template/:id',
    exact: true,
    component: React.lazy(
      () => import('./marketing/personal-message/templateId')
    ),
  },
  {
    path: '/personal-message/post/:id',
    exact: true,
    component: React.lazy(() => import('./marketing/personal-message/postId')),
  },
  {
    path: '/welcome-slider',
    exact: true,
    component: React.lazy(() => import('./marketing/welcome-slider-control')),
  },
  // {
  //   path: '/welcome-slider/new',
  //   exact: true,
  //   component: React.lazy(
  //     () => import('./marketing/welcome-slider-control/new')
  //   ),
  // },
  {
    path: '/welcome-slider/page/:id',
    exact: true,
    component: React.lazy(
      () => import('./marketing/welcome-slider-control/id')
    ),
  },
];

const claims = [
  {
    path: '*',
    exact: true,
    component: React.lazy(() => import('./claims')),
  },
];

const services = [
  {
    path: '*',
    exact: true,
    component: React.lazy(() => import('./services')),
  },
];

const dashboard: Routes[] = [
  {
    path: '/',
    exact: true,
    // component: React.lazy(() => import('./dashboard/index')),
    component: React.lazy(() => import('./dashboard/chats/index')),
  },
  {
    path: '/bookings',
    exact: true,
    component: React.lazy(() => import('./dashboard/bookings')),
  },
  {
    path: '/chats',
    exact: true,
    component: React.lazy(() => import('./dashboard/chats/index')),
  },
  {
    path: '/chats/settings',
    exact: true,
    component: React.lazy(() => import('./dashboard/chat-widget-settings')),
  },
  {
    path: '/marketing',
    exact: true,
    component: React.lazy(() => import('./dashboard/marketing')),
  },
  {
    path: '/claims',
    exact: true,
    component: React.lazy(() => import('./dashboard/claims')),
  },
  {
    path: '/services',
    exact: true,
    component: React.lazy(() => import('./dashboard/services')),
  },
  {
    path: '/customers',
    exact: true,
    component: React.lazy(() => import('./dashboard/customers')),
  },
  {
    path: '/jobsheets',
    exact: true,
    component: React.lazy(() => import('./dashboard/jobsheets')),
  },
  {
    path: '/admin',
    exact: true,
    component: React.lazy(() => import('./dashboard/admin/index')),
  },
  {
    path: '/admin/users',
    exact: true,
    component: React.lazy(() => import('./dashboard/admin/users')),
  },
  {
    path: '/admin/invoices',
    exact: true,
    component: React.lazy(() => import('./dashboard/admin/invoices')),
  },
  {
    path: '/admin/settings',
    exact: true,
    component: React.lazy(() => import('./dashboard/admin/settings')),
  },
];

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  marketing,
  claims,
  services,
  dashboard,
};
