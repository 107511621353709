import styled from 'styled-components';
import { colors } from '../../constants';

export const StyledEditor = styled.div`
  border: 1px solid ${colors.azureishWhite};
  padding: 10p;
  min-height: 200px;
`;

export const EditorContainer = styled.div<{ editMode: boolean }>`
  border: ${({ editMode }) =>
    editMode ? `1px solid ${colors.azureishWhite}` : 'none'};
  padding: ${({ editMode }) => (editMode ? '10px' : '0')};
  min-height: 200px;
  border-radius: 12px;
  &&& .codex-editor__redactor {
    padding: 0 !important;
    margin: ${({ editMode }) => (editMode ? 'inherit' : '0')};
  }

  .ce-block__content {
    max-width: 100%; /* example value, adjust for your own use case */
  }

  .ce-block {
    // width: calc(100% - 50px);
    width: ${({ editMode }) => (editMode ? 'calc(100% - 50px)' : '100%')};
  }

  .ce-toolbar__content {
    max-width: 96%; /* example value, adjust for your own use case */
    padding: 0 10px;
  }

  .ce-toolbar {
    position: absolute;
    left: 100%;
  }
`;

export const TipTapEditorWrapper = styled.div`
  &&& {
    border: 1px solid ${colors.azureishWhite};
    border-radius: 8px;
  }
  .ProseMirror {
    /* min-height: 300px; */
    padding: 10px 20px;
    outline: none;

    > * + * {
      margin-top: 0.75em;
    }

    ul,
    ol {
      padding: 0 1rem;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      line-height: 1.1;
    }

    code {
      background-color: rgba(#616161, 0.1);
      color: #616161;
    }

    pre {
      background: #0d0d0d;
      color: #fff;
      font-family: 'JetBrainsMono', monospace;
      padding: 0.75rem 1rem;
      border-radius: 0.5rem;

      code {
        color: inherit;
        padding: 0;
        background: none;
        font-size: 0.8rem;
      }
    }

    img {
      max-width: 100%;
      height: auto;
    }

    blockquote {
      padding-left: 1rem;
      border-left: 2px solid rgba(#0d0d0d, 0.1);
    }

    hr {
      border: none;
      border-top: 2px solid rgba(#0d0d0d, 0.1);
      margin: 2rem 0;
    }
  }
`;
