import Navbar from './navbar';
import Topbar from './topbar';

const Header = () => {
  return (
    <div style={{ background: '#fff' }}>
      <Topbar />
      {/* <Navbar /> */}
    </div>
  );
};

export default Header;
