import { faPen } from '@fortawesome/free-solid-svg-icons'
import React, { Children, ReactNode } from 'react'
import styled from 'styled-components'
import { Icon } from './icon'

type Prop = {
  children: ReactNode
  handleClick?: () => void
}
export const Card: React.FC<Prop> = ({ children, handleClick }) => {
  return (
    <div>
      <StyledCard>
        {handleClick && (
          <StyledIcon icon={faPen} type="thin" onClick={handleClick} />
        )}
        {children}
      </StyledCard>
    </div>
  )
}

export const StyledCard = styled.div`
  &&& {
    position: relative;
    background: #ffffff;
    border: 1px solid #d5d5da;
    box-shadow: 0px 10px 20px -10px rgba(0, 0, 0, 0.12);
    border-radius: 8px;
    padding: 20px;
    overflow: hidden;
  }
`

export const StyledIcon = styled(Icon)`
  &&& {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
  }
`
