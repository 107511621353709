import { Form } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { ActionLabels, NewsFormProps } from '../molecules'
import { Input } from './input'
import { Select, Option } from './select'

export const CallToAction = ({ form, initialValue, hideUrl, actionLabels = ActionLabels }: NewsFormProps) => {

  const { t } = useTranslation()
  const getUrl = initialValue
    ? actionLabels(t)[initialValue.callToActionUrl || initialValue.url] || ''
    : 'redirect://MakeAppointment';

  const [showCustomFields, setShowCustomFields] = React.useState(
    getUrl === '' ? true : false,
  );

  return (
    <>
      <Form.Item label={t('dashboard.callToAction')} name="url" required>
        <Select
          defaultValue={getUrl}
          id="url"
          width="480px"
          value={form.getFieldValue('url')}
          onChange={(value: keyof typeof actionLabels | '') => {
            if (value === '') {
              setShowCustomFields(true)
            } else {
              setShowCustomFields(false)
            }
          }}
        >
          {Object.keys(actionLabels(t)).map((key: string) => {
            // @ts-ignore
            return (
              <Option value={key} key={key}>
                {actionLabels(t)[key]}
              </Option>
            )
          })}
          {!hideUrl && <Option value="">{t('newCampaign.custom')}</Option>}
        </Select>
      </Form.Item>
      {showCustomFields && (
        <>
          <Form.Item
            label={t('dashboard.ButtonTekst ')}
            name="callToActionText"
            rules={[
              {
                required: true,
                message: 'Call to action label is required',
              },
            ]}
          >
            <Input id="callToActionText" />
          </Form.Item>
        </>
      )}

      {showCustomFields && (
        <Form.Item
          shouldUpdate={true}
          name="callToActionUrl"
          rules={[
            {
              required: true,
              message: 'URL is required',
            },
          ]}
        >
          <Input
            addonBefore="https://"
            id="callToActionUrl"
            label="Call to action (URL)"
          />
        </Form.Item>
      )}
    </>
  )
}
