import React from 'react'
import { Select as AntSelect, SelectProps } from 'antd'
import styled from 'styled-components'

interface StyledSeclectProps extends SelectProps {
  width?: string
  height?: string
}

export const Select = ({ children, ...props }: StyledSeclectProps) => {
  return (
    // @ts-ignore
    <StyledSelect {...props}> {children} </StyledSelect>
  )
}

export const Option = AntSelect.Option

const StyledSelect = styled(AntSelect)<StyledSeclectProps>`
  &&& .ant-select-selector {
    border-radius: 8px;
    outline: none;
    height: ${({ height }) => height || '40px'};
    align-items: center;
  }
  &&& {
    width: ${({ width }) => width || '130px'} !important;
    max-width: ${({ width }) => width || 'initial'};
  }
  &&& .ant-select-item-option-content {
    white-space: nowrap !important;
  }

  &&& .ant-select-selection-item {
    height: 30px;
  }
  &&& .ant-select-selection-search-input {
    margin-top: 10px;
  }
  &&& #tags__.ant-select-selection-search-input {
    margin-top: 0 !important;
  }
`
