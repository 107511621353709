import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import en from './en/en.json';
import nl from './nl/nl.json';

const resources = {
  en: {
    translation: en,
  },
  nl: {
    translation: nl,
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: 'nl',
  fallbackLng: ['en', 'nl'],
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;

const i18nInstance = i18n.createInstance();

export const getSpecificInstance = async (language: 'en' | 'nl') => {
  switch (language) {
    case 'en':
      return i18nInstance.init({
        resources,
        lng: 'en', // set your specific language here
        fallbackLng: ['en', 'nl'],
        interpolation: {
          escapeValue: false,
        },
      });
    case 'nl':
      return i18nInstance.init({
        resources,
        lng: 'nl', // set your specific language here
        fallbackLng: ['en', 'nl'],
        interpolation: {
          escapeValue: false,
        },
      });
  }
};

export const ELANGUAGES = {
  ENGLISH: 'en',
  DUTCH: 'nl',
};

export const LANGUAGES = <T extends (x: string) => void>(t: T) => {
  return [
    {
      value: 'nl',
      label: t('language.dutch'),
      optionLabel: t('language.dutch'),
    },
    {
      value: 'en',
      label: t('language.english'),
      optionLabel: t('language.english'),
    },
  ];
};
