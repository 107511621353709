import { domains } from '../../types';

export const copyToClipBoard = (text: string) => {
  navigator.clipboard.writeText(text);
};

export const getCurrentDomain = () => {
  const currentDomain = window.location.hostname.split('.')[0];
  const themeDomain = Object.keys(domains).find((key) => {
    if (domains[key].includes(currentDomain)) {
      return key;
    }
  });
  console.log(themeDomain, currentDomain, window.location);
  return themeDomain;
};

export const hexToRGBA = (hex, opacity) => {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  return `rgba(${r}, ${g}, ${b}, ${opacity / 100})`;
};

type Props = {
  userId?: string;
  licensePlate?: string;
  GroupId?: string;
  CustomerUserId?: string;
};

export const RedirectToCorrespondingUrl = (other: Props) => {
  const { CustomerUserId, GroupId } = other;

  const url = new URL(window.location.href);
  url.searchParams.delete('nonce');
  if (getCurrentDomain() === 'claims') {
    // if the url has parameters like userId, licensePlate, etc then redirect to services page with those parameters
    // else redirect to services page and pass the group id from the response to the services page
    const params = new URLSearchParams(window.location.search);
    const userId = params.get('UserId');
    const licensePlate = params.get('LicensePlate');
    const defaults = {
      pageNumber: '1',
      pageSize: '10',
      status: '0',
    };

    url.pathname = '/services';
    url.searchParams.set('PageNumber', defaults.pageNumber);
    url.searchParams.set('PageSize', defaults.pageSize);
    url.searchParams.set('Status', defaults.status);
    url.searchParams.delete('nonce');

    if (CustomerUserId) {
      url.searchParams.set('customer', CustomerUserId);
    }
    if (licensePlate) {
      url.searchParams.set('LicencePlate', licensePlate);
    }
    if (GroupId) {
      url.searchParams.set('GroupId', GroupId);
    }

    window.location.assign(url);
  } else {
    // go to host url
    url.pathname = '/';
    if (CustomerUserId) {
      url.searchParams.set('customer', CustomerUserId);
    }
    window.location.assign(url);
  }
};

export function parseEditorJsData(data) {
  const parsedData = JSON.parse(data);
  let text = '';
  for (const block of parsedData.blocks) {
    switch (block.type) {
      case 'paragraph':
        text += block.data.text;
        break;
      case 'header':
        text += block.data.text + '\n';
        break;
      case 'list':
        for (const item of block.data.items) {
          text += '- ' + item.text + '\n';
        }
        break;
      case 'image':
        text += '[Image]' + '\n';
        break;
      case 'video':
        text += '[Video]' + '\n';
        break;
      case 'code':
        text += '[Code]' + '\n';
        break;
      case 'embed':
        text += '[Embed]' + '\n';
        break;
      default:
        text += '[Unknown block type]' + '\n';
        break;
    }
  }
  return text;
}
