import { Tabs as AntdTabs, TabsProps as AntdTabProps } from 'antd';
import React from 'react';
import styled from 'styled-components';
import { colors } from '../constants';



export interface TabProps extends AntdTabProps {
    items: Array<{
        label: string;
        key: string;
        children: React.ReactNode;
    }>;
}

export const Tabs = ({ ...props }: TabProps) => {
    return (
        <StyledAntdTabs {...props}>
            {/* {items.map(({ label, key, children }) => (
                <AntdTabs.TabPane tab={label} key={key}>
                    {children}
                </AntdTabs.TabPane>
            ))} */}
        </StyledAntdTabs>
    );
};

const StyledAntdTabs = styled(AntdTabs)`
    &&& {
        .ant-tabs-tab{
            margin: 0 0 0 10px;
        }
        .ant-tabs-tab {
            font-size: 14px;
            font-weight: 500;
            color: ${colors.grey};
            text-transform: capitalize;
        }

        .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
            color: ${({ theme }) => theme.groupID.primary} !important;
        }

        .ant-tabs-ink-bar {
            background-color: ${({ theme }) => theme.groupID.primary};
            height: 3px !important;
        }
        .ant-tabs-tab-btn {
            margin: 0 10px;
        }
        .ant-tabs-content-holder {
            padding: 20px 10px;
        }
    }
`
