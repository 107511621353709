/** module not found errors */
// @ts-nocheck
// import Embed from '@editorjs/embed';
// import Table from '@editorjs/table';
import List from '@editorjs/list';
// import Warning from '@editorjs/warning';
import Code from '@editorjs/code';
import LinkTool from '@editorjs/link';
import Image from '@editorjs/image';
import Raw from '@editorjs/raw';
import Header from '@editorjs/header';
import Quote from '@editorjs/quote';
import Marker from '@editorjs/marker';
import CheckList from '@editorjs/checklist';
import Delimiter from '@editorjs/delimiter';
import InlineCode from '@editorjs/inline-code';
// import SimpleImage from '@editorjs/simple-image';
import { uploadFile } from 'react-s3';

const config = {
  bucketName: process.env.REACT_APP_S3_UPLOAD_BUCKET,
  region: process.env.REACT_APP_S3_UPLOAD_REGION,
  accessKeyId: process.env.REACT_APP_S3_UPLOAD_KEY,
  secretAccessKey: process.env.REACT_APP_S3_UPLOAD_SECRET,
  dirName: 'images' /* optional */,
};

export const EDITOR_JS_TOOLS = {
  // embed: Embed,
  // table: Table,
  marker: Marker,
  list: List,
  // warning: Warning,
  code: Code,
  linkTool: LinkTool,
  image: {
    class: Image,
    config: {
      uploader: {
        uploadByFile(file) {
          return uploadFile(file, config).then((e) => {
            return {
              success: 1,
              file: {
                url: e.location,
              },
            };
          });
        },
      },
    },
  },
  // input: SimpleInput,
  // radio: SimpleRating,
  raw: Raw,
  header: {
    class: Header,
    config: {
      defaultLevel: 2,
    },
  },
  quote: Quote,
  delimiter: Delimiter,
  inlineCode: InlineCode,
  // simpleImage: SimpleImage,
};
