import { Form, FormInstance } from 'antd';
import React from 'react';

import { FileInput, ImagePicker, Input } from '../../atom/input';
import { Select, Option } from '../../atom/select';
import * as S from './styles';
import _t from '../../../locales';

import Editor from '../../atom/editor/editor';
import { useTranslation } from 'react-i18next';
import { CallToAction } from '../../atom';

export interface NewsFormProps {
  form: FormInstance;
  initialValue?: Record<string | number, any>;
  hideUrl?: boolean;
  actionLabels?: (t) => Record<string, string>;
}

export const ActionLabels = (t) =>
  ({
    'redirect://MakeAppointment': t('newCampaign.makeBooking'),
    'redirect://MyTyre': t('newCampaign.myTyres'),
    'redirect://RoadSideAssistance': t('newCampaign.roadsideAssistance'),
    'redirect://SelfDiagnosis': t('newCampaign.selfDiagnosis'),
  } as const);

export const NewsForm = ({ form, initialValue }: NewsFormProps) => {
  const { t } = useTranslation();

  return (
    <S.FormWrapper>
      <Form layout="vertical" form={form}>
        <Form.Item
          shouldUpdate
          name="title"
          rules={[
            {
              required: true,
              message: 'Title is required',
            },
          ]}
        >
          <Input
            placeholder={t('dashboard.title')}
            label={t('dashboard.title')}
            id="title"
          />
        </Form.Item>
        <Form.Item
          shouldUpdate
          name="subTitle"
          rules={[
            {
              required: true,
              message: 'Sub title is required',
            },
          ]}
        >
          <Input
            placeholder={t('dashboard.subTitle')}
            label={t('dashboard.subTitle')}
            id="subTitle"
          />
        </Form.Item>
        <CallToAction form={form} initialValue={initialValue} />
        {/* <Form.Item shouldUpdate name={'image'}>
          <FileInput
            type="file"
            label={t('dashboard.image')}
            rules={[
              {
                required: true,
                message: 'Image is required',
              },
            ]}
            name="image"
            value={form.getFieldValue('image')}
            handleChange={(value) => form.setFieldValue('image', value)}
          />
        </Form.Item> */}

        <Form.Item
          label={t('dashboard.image')}
          shouldUpdate
          rules={[
            {
              required: true,
              message: 'Image is required',
            },
          ]}
          name="image"
        >
          <ImagePicker
            value={form.getFieldValue('image')}
            onChange={(value) => form.setFieldValue('image', value)}
          />
        </Form.Item>

        <Form.Item
          shouldUpdate
          label={t('dashboard.editor')}
          required
          name="content"
          rules={[
            {
              required: true,
              message: 'content is required',
            },
          ]}
          style={{
            margin: '5px',
          }}
        >
          <Editor
            editMode={true}
            id={'content'}
            data={initialValue?.content && JSON.parse(initialValue.content)}
            onChange={(value) => {
              form.setFieldValue('content', value);
            }}
          />
        </Form.Item>
        <a
          href="https://getemoji.com/"
          target="_blank"
          referrerPolicy="no-referrer"
          rel="noreferrer"
          style={{ marginLeft: 5 }}
        >
          {t('general.getEmoticons')}
        </a>
      </Form>
    </S.FormWrapper>
  );
};
