import React from 'react'
import { Table as AntDTable, TableProps, } from 'antd'
import styled from 'styled-components';
import { colors } from '../constants';

export type StyledTableProps<RecordType> = TableProps<RecordType>;


/**
 * marketing Tabe
 * @param props
 * @constructor
 */

export const Table = <T,>(props: StyledTableProps<T>) => {
    return (
        // @ts-ignore
        <StyledTableContainer {...props} pagination={{
            ...props.pagination,
        }} />
    )
}


export const StyledTableContainer = styled(AntDTable)`
    &&& {
        // small shawdow
            .ant-table-thead > tr > th, .ant-table-tbody > tr > td {
            padding: 10px 16px;
            ::before {
                display: none;
            }
        }
        .ant-table-thead > tr > th {
            background: ${colors.magnolia};
        }
        .ant-table-thead > tr, .ant-table-tbody > tr {
            border-top: 1px solid ${colors.azureishWhite};
            font-size: 16px;
            height: 60px;
        }
        .ant-pagination-options{
            display: none;
        }

        .ant-table-tbody > tr :not(:first-of-type){
            color: ${colors.grey};
        }
        .ant-table-container{
            border: 1px solid ${colors.azureishWhite};
            border-radius: 4px;
            overflow: hidden !important;
            box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
        }
    }
`;


export const ClaimsTable = <T,>(props: StyledTableProps<T>) => {
    return (
        // @ts-ignore
        <StyledClaimsTableContainer {...props} pagination={{
            ...props.pagination,
        }} />
    )
}

export const StyledClaimsTableContainer = styled(AntDTable)`
    &&& {
        // small shawdow
            .ant-table-thead > tr > th, .ant-table-tbody > tr > td {
            padding: 10px 16px;
            ::before {
                display: none;
            }
        }
        .ant-table-thead > tr > th {
            background: ${colors.magnolia};
        }
        .ant-table-thead > tr, .ant-table-tbody > tr {
            border-top: 1px solid ${colors.azureishWhite};
            font-size: 16px;
            height: 60px;
        }
        .ant-pagination-options{
            display: none;
        }

        .ant-table-tbody > tr :not(:first-of-type){
            color: ${colors.grey};
        }
        .ant-table-container{
            border: 1px solid ${colors.azureishWhite};
            border-radius: 4px;
            overflow: hidden !important;
            box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
        }
    }
`;
