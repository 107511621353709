import axios, { Method, AxiosRequestHeaders } from 'axios';
import { t } from 'i18next';

type useAxiosProps = {
  url: string;
  method: string | Method;
  body?: Record<string, unknown>;
  headers?: AxiosRequestHeaders;
  isGarageApp?: boolean;
};

axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;

// 'http://localhost:3001'
//
export const fetchHook = async ({
  url,
  method = 'get',
  body,
  headers,
}: useAxiosProps): Promise<{
  response?: Record<string, any>;
  error?: Error | string;
}> => {
  try {
    const response = await axios({
      url,
      method,
      withCredentials: false,
      data: body,
      headers: {
        ...headers,
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json',
      },
    });
    return { response: response.data };
  } catch (error) {
    return {
      response: undefined,
      error: error.response?.data?.message?.includes('token')
        ? t('toast.tokenError')
        : error.response?.data?.message || t('toast.youAreNotLoggedIn'),
    };
  }
};

export default fetchHook;
