import React from 'react';

export const SettingsIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth="1.5"
    stroke="currentColor"
    aria-hidden="true"
    className="icon"
    {...props}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M9.594 3.94c.09-.542.56-.94 1.11-.94h2.593c.55 0 1.02.398 1.11.94l.213 1.281c.063.374.313.686.645.87.074.04.147.083.22.127.324.196.72.257 1.075.124l1.217-.456a1.125 1.125 0 011.37.49l1.296 2.247a1.125 1.125 0 01-.26 1.431l-1.003.827c-.293.24-.438.613-.431.992a6.759 6.759 0 010 .255c-.007.378.138.75.43.99l1.005.828c.424.35.534.954.26 1.43l-1.298 2.247a1.125 1.125 0 01-1.369.491l-1.217-.456c-.355-.133-.75-.072-1.076.124a6.57 6.57 0 01-.22.128c-.331.183-.581.495-.644.869l-.213 1.28c-.09.543-.56.941-1.11.941h-2.594c-.55 0-1.02-.398-1.11-.94l-.213-1.281c-.062-.374-.312-.686-.644-.87a6.52 6.52 0 01-.22-.127c-.325-.196-.72-.257-1.076-.124l-1.217.456a1.125 1.125 0 01-1.369-.49l-1.297-2.247a1.125 1.125 0 01.26-1.431l1.004-.827c.292-.24.437-.613.43-.992a6.932 6.932 0 010-.255c.007-.378-.138-.75-.43-.99l-1.004-.828a1.125 1.125 0 01-.26-1.43l1.297-2.247a1.125 1.125 0 011.37-.491l1.216.456c.356.133.751.072 1.076-.124.072-.044.146-.087.22-.128.332-.183.582-.495.644-.869l.214-1.281z"
    />
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
    />
  </svg>
);

export const DoubleChevron = (props) => (
  <svg
    width="10"
    height="12"
    viewBox="0 0 10 12"
    fill="none"
    stroke="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5.3584 11.7891C5.14746 11.9531 4.84277 11.9531 4.65527 11.7891L0.905273 8.78906C0.647461 8.60156 0.624023 8.25 0.811523 7.99219C0.999023 7.75781 1.35059 7.71094 1.6084 7.92188L5.00684 10.6406L8.40527 7.92188C8.63965 7.71094 8.99121 7.75781 9.17871 7.99219C9.36621 8.25 9.34277 8.60156 9.08496 8.78906L5.33496 11.7891H5.3584ZM9.1084 3.42188C9.34277 3.60938 9.38965 3.96094 9.17871 4.19531C8.99121 4.45312 8.63965 4.47656 8.40527 4.28906L5.00684 1.57031L1.6084 4.28906C1.35059 4.47656 0.999023 4.45312 0.811523 4.19531C0.624023 3.96094 0.647461 3.60938 0.905273 3.42188L4.65527 0.421875C4.84277 0.257812 5.14746 0.257812 5.3584 0.421875L9.1084 3.42188Z"
      fill="black"
    />
  </svg>
);

export const BrushIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth="1.5"
    stroke="currentColor"
    aria-hidden="true"
    className="icon"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M9.53 16.122a3 3 0 00-5.78 1.128 2.25 2.25 0 01-2.4 2.245 4.5 4.5 0 008.4-2.245c0-.399-.078-.78-.22-1.128zm0 0a15.998 15.998 0 003.388-1.62m-5.043-.025a15.994 15.994 0 011.622-3.395m3.42 3.42a15.995 15.995 0 004.764-4.648l3.876-5.814a1.151 1.151 0 00-1.597-1.597L14.146 6.32a15.996 15.996 0 00-4.649 4.763m3.42 3.42a6.776 6.776 0 00-3.42-3.42"
    ></path>
  </svg>
);

export const SearchIcon = (props) => (
  <svg
    width="17"
    height="17"
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.5 6.5C11.5 4.71875 10.5312 3.09375 9 2.1875C7.4375 1.28125 5.53125 1.28125 4 2.1875C2.4375 3.09375 1.5 4.71875 1.5 6.5C1.5 8.3125 2.4375 9.9375 4 10.8438C5.53125 11.75 7.4375 11.75 9 10.8438C10.5312 9.9375 11.5 8.3125 11.5 6.5ZM10.5312 11.625C9.40625 12.5 8 13 6.5 13C2.90625 13 0 10.0938 0 6.5C0 2.9375 2.90625 0 6.5 0C10.0625 0 13 2.9375 13 6.5C13 8.03125 12.4688 9.4375 11.5938 10.5625L15.7812 14.7188C16.0625 15.0312 16.0625 15.5 15.7812 15.7812C15.4688 16.0938 15 16.0938 14.7188 15.7812L10.5312 11.625Z"
      fill="#2C2F42"
    />
  </svg>
);

export const InfoIcon = ({ color }: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    fill={color}
    version="1.1"
    id="Capa_1"
    width="18px"
    height="18px"
    viewBox="0 0 40 40"
    xmlSpace="preserve"
  >
    <g>
      <path d="M20,0C8.974,0,0,8.973,0,20c0,11.027,8.974,20,20,20c11.029,0,20-8.973,20-20C40,8.973,31.029,0,20,0z M23.439,31.749   c0,1.048-0.851,1.896-1.896,1.896h-3.086c-1.049,0-1.896-0.849-1.896-1.896V21.454c0-1.047,0.849-1.896,1.896-1.896h3.086   c1.047,0,1.896,0.85,1.896,1.896V31.749z M23.439,15.764c0,1.049-0.851,1.896-1.896,1.896h-3.086c-1.049,0-1.896-0.85-1.896-1.896   v-3.25c0-1.049,0.849-1.896,1.896-1.896h3.086c1.047,0,1.896,0.849,1.896,1.896V15.764z" />
    </g>
  </svg>
);

export const TrashIcon = (props: any) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 13 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3.69141 1.24219C3.82812 0.941406 4.12891 0.75 4.45703 0.75H7.76562C8.09375 0.75 8.39453 0.941406 8.53125 1.24219L8.75 1.625H11.375C11.8398 1.625 12.25 2.03516 12.25 2.5C12.25 2.99219 11.8398 3.375 11.375 3.375H0.875C0.382812 3.375 0 2.99219 0 2.5C0 2.03516 0.382812 1.625 0.875 1.625H3.5L3.69141 1.24219ZM11.375 4.25L10.7734 13.5195C10.7461 14.2305 10.1719 14.75 9.46094 14.75H2.76172C2.05078 14.75 1.47656 14.2305 1.44922 13.5195L0.875 4.25H11.375Z"
      fill="currentColor"
    />
  </svg>
);
