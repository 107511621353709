import { Col, Form, FormInstance, message, Row } from 'antd';
import React from 'react';
import { Input, TextArea } from '../../atom/input';
import * as S from './styles';

import Editor from '../../atom/editor/editor';
import { Select, Option } from '../../atom/select';
import { useTranslation } from 'react-i18next';

import { NotificationTags, NotificationTemplate } from '../../../types';
import { copyToClipBoard } from '../../utils';
import { CallToAction, Tag } from '../../atom';

enum ActionLabelsDirections {
  Dashboard = 'Dashboard',
  MakeAppointment = 'MakeAppointment',
  AcceptGarage = 'AcceptGarage',
  UpdateMilage = 'UpdateMilage',
  ViewTax = 'ViewTax',
  MotExpires = 'MotExpires',
  GoToChat = 'GoToChat',
  GoToBookings = 'GoToBookings',
  GoToService = 'GoToService',
  MyTyre = 'MyTyre',
  ViewMaintenance = 'ViewMaintenance',
  makeABooking = 'makeABooking',
}

export const NotificationsMap = (t) =>
  ({
    [ActionLabelsDirections.Dashboard]: t('notification.activateVehicle'),
    [ActionLabelsDirections.AcceptGarage]: t('notification.acceptGarage'),
    [ActionLabelsDirections.UpdateMilage]: t('notification.updateMilage'),
    [ActionLabelsDirections.ViewTax]: t('notification.viewTax'),
    [ActionLabelsDirections.MotExpires]: t('notification.mot'),
    [ActionLabelsDirections.GoToChat]: t('notification.goToChat'),
    [ActionLabelsDirections.GoToBookings]: t('notification.goToBooking'),
    [ActionLabelsDirections.GoToService]: t('notification.goToService'),
    [ActionLabelsDirections.ViewMaintenance]: t('notification.viewMaintenance'),
    [ActionLabelsDirections.MyTyre]: t('notification.myTyres'),
    [ActionLabelsDirections.makeABooking]: t('notification.makeABooking'),
  } as const);

export const TabContent = ({
  initialData,
  form,
  tags,
  id,
}: {
  initialData?: NotificationTemplate;
  form?: FormInstance;
  tags: NotificationTags[];
  id: string;
}) => {
  const { t } = useTranslation();

  const copy = () => {
    // copy to clipboard
    copyToClipBoard(form.getFieldValue('tag'));
    message.success(t('dashboard.tagCopied'));
  };

  return (
    <S.FormWrapper>
      <Form.Item
        shouldUpdate
        label={t('notifications.formSubject')}
        name="subject"
        rules={[
          {
            required: true,
            message: t('notifications.formSubjectRequired'),
          },
        ]}
      >
        <Input
          placeholder={t('dashboard.title')}
          id="subject"
          rules={[
            {
              required: true,
              message: 'Title is required',
            },
          ]}
          defaultValue={initialData?.title}
          initialValue={initialData?.title}
          maxLength={48}
          hint={t('notifications.wordLengthHint', { max: 48 })}
        />
      </Form.Item>
      <Form.Item
        shouldUpdate
        label={t('notifications.strokeMessage')}
        name="strokeMessage"
        rules={[
          {
            required: true,
            message: 'Stroke message is required',
          },
        ]}
      >
        <TextArea
          id="strokeMessage"
          initialValue={initialData?.strokeMessage}
          maxLength={98}
          hint={t('notifications.wordLengthHint', { max: 98 })}
          onChange={(e) => form.setFieldValue('strokeMessage', e.target.value)}
        />
      </Form.Item>

      <Form.Item
        required
        name={'content'}
        rules={[
          {
            required: true,
            message: 'content is required',
          },
        ]}
        label={t('dashboard.editor')}
        shouldUpdate
        style={{
          margin: '5px',
        }}
      >
        <Editor
          onChange={(data) => {
            console.log(data);
            form.setFieldValue('content', data);
          }}
          data={initialData?.content && JSON.parse(initialData.content)}
          id={id}
        />
      </Form.Item>
      <Row justify="space-between" gutter={8} style={{ marginBottom: 24 }}>
        <a
          href="https://getemoji.com/"
          target="_blank"
          referrerPolicy="no-referrer"
          rel="noreferrer"
          style={{ marginLeft: 5 }}
        >
          {t('general.getEmoticons')}
        </a>

        <Row gutter={8}>
          <Col>
            <S.Label>{t('notifications.dynamicLink')}</S.Label>
          </Col>
          <Col>
            <div>
              <Select
                id="tags__"
                showSearch
                style={{ fontSize: 12 }}
                height={'30px'}
                placeholder={t('claims.search') + ' tag'}
                dropdownMatchSelectWidth={false}
                dropdownStyle={{
                  fontSize: 12,
                }}
                onChange={(value) => form.setFieldValue('tag', value)}
              >
                {tags.map((tag: NotificationTags) => (
                  <Option
                    key={tag.id}
                    style={{
                      fontSize: 12,
                      wordWrap: 'break-word',
                    }}
                    value={tag.tag}
                  >
                    <div
                      style={{
                        fontSize: 12,
                        wordWrap: 'break-word',
                      }}
                    >
                      <span>
                        {' '}
                        <b>data:</b> {tag.Localizations[0]?.data}
                      </span>{' '}
                      <br />
                      <span>
                        <b>tag:</b> <u>{tag.tag}</u>
                      </span>
                      <br />
                      <span>
                        <b>description:</b> {tag.Localizations[0]?.description}
                      </span>
                      <br />
                      <span>
                        <b>example: </b> {tag.Localizations[0]?.example}
                      </span>
                    </div>
                  </Option>
                ))}
              </Select>
              <Tag
                onClick={copy}
                style={{
                  marginLeft: 10,
                  fontSize: 14,
                  cursor: 'pointer',
                  color: 'white',
                  borderRadius: '8px',
                  height: '30px',
                  display: 'inline-flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
                color={'black'}
              >
                <span
                  style={{
                    color: 'white',
                  }}
                >
                  {t('notifications.copy')}
                </span>
              </Tag>
            </div>
          </Col>
        </Row>
      </Row>

      <CallToAction
        form={form}
        initialValue={initialData}
        actionLabels={NotificationsMap}
      />
      <Form.Item
        shouldUpdate
        label={t('notifications.signatureName')}
        name="signature"
      >
        <TextArea id="signature" />
      </Form.Item>
    </S.FormWrapper>
  );
};
