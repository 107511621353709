import { Row, Col } from "antd"
import Title from "antd/lib/typography/Title";
import styled from "styled-components";


export interface AuthLayoutProps {
    children: React.ReactNode,
    title: string
}

export const AuthLayout = ({ children, title }: AuthLayoutProps) => {
    return (
        <AuthLayoutContainer>
            <Col span={14}>
                <AuthLayoutForm>
                    <Title> {title} </Title>
                    {children}
                </AuthLayoutForm>
            </Col>
            <AuthFormBackground span={10}>
                {/* <img src="https://igarage.eu/app/themes/default/assets/images/car.png" /> */}
            </AuthFormBackground>
        </AuthLayoutContainer>
    );
}

const AuthLayoutContainer = styled(Row)`
    display: flex;
    justify-content: center;
    align-items: center;
`;

const AuthFormBackground = styled(Col)`
    background-color: #0be79d;
    height: 100vh;
    display: flex;
    align-content: center;
    background-image: url("https://igarage.eu/app/themes/default/assets/images/car.png");
    background-repeat: no-repeat;
    background-position: center;
    img {
        width: 100%;
    }
    & .ant-col.ant-form-item-label{
        margin-bottom: 40px !important;
    }
`;

const AuthLayoutForm = styled(Col)`
    width: 50%;
    margin: 0 auto;

    button {
        color: ${({ theme }) => theme.colors.green40};
        margin-top: 40px;
    }
`;