export const DATE_FORMAT = 'DD-MM-YYYY';
export const TIME_FORMAT = 'HH:mm';
export enum PMType {
  SCHEDULED = 'SCHEDULED',
  TEMPLATE = 'TEMPLATE',
}

const S3_BUCKET = process.env.REACT_APP_S3_UPLOAD_BUCKET;
const REGION = process.env.REACT_APP_S3_UPLOAD_REGION;
const ACCESS_KEY = process.env.REACT_APP_S3_UPLOAD_KEY;
const SECRET_ACCESS_KEY = process.env.REACT_APP_S3_UPLOAD_SECRET;

export const s3Config = {
  bucketName: S3_BUCKET,
  region: REGION,
  accessKeyId: ACCESS_KEY,
  secretAccessKey: SECRET_ACCESS_KEY,
  dirName: 'images' /* optional */,
};
