import {
  Form,
  FormItemProps,
  Input as AntdInput,
  InputProps,
  message,
  Tag,
} from 'antd';

import { PlusOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import { useS3Upload } from 'next-s3-upload';
import i18n from 'i18next';
import styled from 'styled-components';
//@ts-ignore
import { uploadFile } from 'react-s3';
import { colors } from '../constants';
import { Button } from './button';
import { useTranslation } from 'react-i18next';
import { TextAreaProps } from 'antd/lib/input';
import { useFileUpload } from '../molecules/welcomeSliderControl/hooks/useFileUpload';

const FormItem = Form.Item;

const S3_BUCKET = process.env.REACT_APP_S3_UPLOAD_BUCKET;
const REGION = process.env.REACT_APP_S3_UPLOAD_REGION;
const ACCESS_KEY = process.env.REACT_APP_S3_UPLOAD_KEY;
const SECRET_ACCESS_KEY = process.env.REACT_APP_S3_UPLOAD_SECRET;

const config = {
  bucketName: S3_BUCKET,
  region: REGION,
  accessKeyId: ACCESS_KEY,
  secretAccessKey: SECRET_ACCESS_KEY,
  dirName: 'images' /* optional */,
};

export const Input = ({
  hint,
  ...props
}: FormItemProps &
  InputProps & {
    hint?: string;
    width?: string;
    height?: string;
  }) => {
  return (
    <StyledFormItem {...props}>
      {props.type === 'password' ? (
        <Password {...props} />
      ) : (
        <StyledInput {...props} />
      )}
      {hint && <Hint> {hint}</Hint>}
    </StyledFormItem>
  );
};

export const TextArea = ({
  hint,
  ...props
}: FormItemProps &
  TextAreaProps & {
    hint?: string;
  }) => {
  return (
    <StyledFormItem>
      <StyledTextArea {...props} rows={10} />
      {hint && <Hint> {hint}</Hint>}
    </StyledFormItem>
  );
};

export const FileInput = ({
  handleChange,
  ...props
}: FormItemProps &
  InputProps & {
    handleChange: (url: string) => void;
  }) => {
  const { t } = useTranslation();
  window.Buffer = window.Buffer || require('buffer').Buffer;
  const [loading, setLoading] = useState(false);
  const [file, _] = useState<File | string>();
  const { FileInput: Filed, openFileDialog } = useS3Upload({});

  const handleFileUpload = async (file?: File) => {
    console.log({ file });

    try {
      setLoading(true);
      if (!file) return;
      // ReactS3Client
      uploadFile(file, config)
        .then((e: any) => {
          handleChange(e.location);
          message.success(t('message.imageUploadSuccessful'));
          setLoading(false);
        })
        .catch((e) => {
          message.error(t('message.imageUploadFailed'));
          setLoading(false);
        });
    } catch (e) {
      message.error(t('message.imageUploadFailed'));
    }
  };

  return (
    <StyledFormItem {...props}>
      <StyledFileInputContainer>
        <p>
          {props.value
            ? props.value
            : file
            ? file
            : t('dashboard.dropImageToUpload')}
        </p>
        {/*// @ts-ignore*/}
        <Button onClick={() => openFileDialog()} loading={loading}>
          {!loading ? t('dashboard.selectImage') : 'Loading...'}
        </Button>
        <Filed
          onChange={handleFileUpload}
          value={file}
          // accept="image/*"
          accept="image/*"
        />
      </StyledFileInputContainer>
    </StyledFormItem>
  );
};

export const ImagePicker = ({ onChange, value }) => {
  const { FileInput, openFileDialog, loading, handleFileUpload, preview } =
    useFileUpload(onChange);

  const [imageSrc, setImageSrc] = useState(value || null);

  useEffect(() => {
    setImageSrc(value);
  }, [value]);

  return (
    <ImagePickerContainer onClick={openFileDialog}>
      {loading && (
        <Tag
          color="orange"
          style={{
            borderColor: 'transparent',
            textAlign: 'center',
            fontSize: 10,
            position: 'absolute',
            top: 0,
            left: 0,
            zIndex: 1000,
          }}
        >
          {i18n.t('general.uploading')}...
        </Tag>
      )}

      {imageSrc || preview ? (
        <ImagePreview src={preview || imageSrc} alt="Selected Image" />
      ) : (
        <>
          <UploadText>
            {loading ? i18n.t('general.uploading') : i18n.t('general.upload')}
          </UploadText>
          <PlusOutlined style={{ fontSize: 20, color: '#333', zIndex: 1 }} />
        </>
      )}
      <FileInput onChange={handleFileUpload} accept="image/*" />
    </ImagePickerContainer>
  );
};

const ImagePickerContainer = styled.div`
  border: 1.5px dashed #d9d9d9;
  width: 160px;
  height: 160px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  overflow: hidden;
  position: relative;
  background-color: #fff;

  &:hover {
    background-color: rgba(255, 0, 0, 0.05);
  }
`;

const ImagePreview = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 20px;
  position: absolute;
  top: 0;
  left: 0;
`;

const UploadText = styled.p`
  margin: 0;
  font-size: 14px;
  font-weight: 400;
  color: #333;
  z-index: 2;
`;

const StyledFileInputContainer = styled.section`
  border: 2px dashed ${colors.azureishWhite};
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0px;
  max-width: 480px;
  border-radius: 8px;

  span {
    margin: 10px;
  }
  > p {
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 10px;
    white-space: nowrap;
  }
`;

const StyledInput = styled(AntdInput)<InputProps>`
  &&&,
  &&& .ant-input-group,
  input {
    border-radius: 8px;
    outline: none;
    height: ${({ height }) => height || '40px'};
  }

  .ant-input-group-addon {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 8px;
    border-top-left-radius: 8px;
  }
`;

const StyledTextArea = styled(AntdInput.TextArea)<TextAreaProps>`
  &&&,
  &&& .ant-input-group,
  input {
    border-radius: 8px;
    outline: none;
    height: 70px;
  }

  .ant-input-group-addon {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 8px;
    border-top-left-radius: 8px;
  }
`;

const Password = styled(AntdInput.Password)<InputProps>`
  &&& {
    border-radius: 8px;
    outline: none;
    height: 40px;
    border: 1px solid ${colors.azureishWhite};
  }
`;

const StyledFormItem = styled(FormItem)<
  FormItemProps & {
    width?: string | number;
    height?: string | number;
  }
>`
  .ant-form-item-explain div {
    font-size: 10px;
  }

  .ant-input-status-error,
  .ant-input-group-wrapper-status-error .ant-input-group-addon {
    border: 1px solid ${colors.azureishWhite} !important;
  }

  &&& {
    margin-bottom: 0px;
    max-width: ${({ width }) => width ?? '480px'};
  }

  .ant-input-affix-wrapper {
    align-items: center;
    padding: 10px;
    outline: none;
    height: ${({ height }) => height || '46px'};
    input {
      border-radius: 0;
      height: 36px;
    }
  }
`;

const Hint = styled.p`
  font-size: 10px;
  margin: 0;
`;
