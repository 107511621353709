import { createContext, useContext, useEffect, useState } from 'react';
import { colors } from '../components';
import { useAuthContext } from './auth';
import clients, { Clients } from '../constants/clients';

export type createGroupIDAssets = {
  groupAssets?: {
    colors?: {
      [key: string]: string;
    };
    logo?: string;
    iosUrl?: string;
    androidUrl?: string;
  };
  setGroupAssets?: (data: Record<string, string>) => void;
};

const GroupIdContext = createContext<createGroupIDAssets>({
  groupAssets: undefined,
  setGroupAssets: () => {},
});

export interface GroupIdAssetsProviderProps {
  children: React.ReactNode;
}

export const GroupIdAssetsProvider = ({
  children,
}: GroupIdAssetsProviderProps) => {
  const { user } = useAuthContext();
  const getGroupIDAssets = async () => {
    const theme: Clients = clients[user?.groupName];
    setGroupAssets({
      colors: {
        ...colors,
        primary: theme?.theme?.dark.primary || colors.primary,
        secondary: theme?.theme?.dark.secondary || colors.secondary,
      },
      logo: theme?.logo || '/images/igarage.png',
      iosUrl: theme?.appUrl.ios,
      androidUrl: theme?.appUrl.android,
    });

    // change link rel icon to the logo of the group
    const link = document.querySelector("link[rel*='icon']") as HTMLLinkElement;
    link.type = 'image/png';
    link.rel = 'shortcut icon';
    link.href = theme?.logo || 'logo.svg';
  };

  useEffect(() => {
    getGroupIDAssets();
  }, []);

  const [groupAssets, setGroupAssets] =
    useState<createGroupIDAssets['groupAssets']>();

  return (
    <GroupIdContext.Provider value={{ groupAssets, setGroupAssets }}>
      {children}
    </GroupIdContext.Provider>
  );
};

export const useGroupAssetsContext = () => useContext(GroupIdContext);
