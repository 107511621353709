import { Modal, Row , FormInstance} from 'antd'
import React, { useEffect } from 'react'
import { Text, Button } from '../../atom';
import { Gap } from '../../atom'

import Editor from "../../atom/editor/editor"

type PreviewModalProps = {
    showPreview: boolean
    setShowPreview: (payload: boolean) => void
    title: string
    image: string
    content: string
    callToActionText: string
    form?: FormInstance
}

export const PreviewModal = ({
    showPreview,
    setShowPreview,
    title,
    image,
    content,
    callToActionText,
    form
}: PreviewModalProps) => {
    useEffect(() => {
        console.log(form?.getFieldsValue())
    },[showPreview,setShowPreview])

    return (
        <>
            <Modal open={showPreview} onCancel={() => setShowPreview(false)} title={"Preview"} width="350px" footer={null}>
                <div>
                    <Text level={3}>
                        {title}
                    </Text>
                    <Gap gap={10} />
                    <Gap gap={10} />
                    <img src={image} style={{ width: '100%' }} />
                </div>
                <Gap gap={10} />
                <Editor id={"dffddfdgdfdf"} editMode={false} data={
                    content &&
                    JSON.parse(content)
                } />
                <Row align={"middle"} justify={"center"}>
                    <Button style={{ width: '100%', marginTop: '30px' }}> {callToActionText} </Button>
                </Row>
            </Modal>
        </>
    )
}
