import React, { forwardRef } from 'react';
import { useGroupAssetsContext } from '../../../context/groupIdColors';
import Header from './components/header';

interface DashboardLayoutProps {
  children: JSX.Element;
}

export const DashboardLayout = forwardRef<HTMLElement, DashboardLayoutProps>(
  ({ children }, ref) => {
    return (
      <section ref={ref} style={{ background: '#F5F7FB' }}>
        <Header />
        <main>{children}</main>
      </section>
    );
  }
);
