import { Button, Dropdown } from 'antd'
import styled from 'styled-components'

export const Header = styled.header`
  padding: 16px 26px;
  display: flex;
  background: #ffffff;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  font-weight: 600;
`

export const Main = styled.main`
  background: #d6dde43d;
  min-height: 100vh;
`

export const SubTitle = styled.div`
  border-bottom: 1px solid #d7dbee;
  font-size: 20px;
  padding: 16px;
  h3 svg {
    margin-right: 20px;
    cursor: pointer;
  }
  h3 {
    margin: 0;
  }
`

export const MenuButton = styled(Button)`
  &&& {
    border: none;
  }
`

export const HeaderControl = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  p {
    margin: 0;
    text-transform: Capitalize;
  }
`

export const DropDown = styled(Dropdown)`
  border: 1px solid #e8e8e8;
  border-radius: 8px;
  padding: 8px 16px;
  height: 40px;
  cursor: pointer;
`

export const DropdownOption = styled.div`
  // shadow for dropdown
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  padding: 8px 16px;
  background: #ffffff;
  p {
    min-width: 150px;
    cursor: pointer;
    margin: 10px 0;
    &&& hover: {
      background: green !important;
      color: #fff;
    }
  }
`

export const Wrapper = styled.div`
  padding: 26px 90px;
`
