import {
  ChangeEvent,
  ElementRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Button as AntdButton } from 'antd';
import styled from 'styled-components';
import debounce from 'lodash/debounce';
import {
  defaultSelectedGarage,
  useAuthContext,
} from '../../../../context/auth';
import chatApiService from '../../../../domains/dashboard/chats/api';
import { DoubleChevron } from '../../../../icons';

const DropdownContainer = styled.div`
  z-index: 1000;
  background-color: white;
  border-radius: 0.5rem;
  border: 1px solid #d7dbee;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  min-width: 20rem;
  max-height: 320px;
  overflow-y: auto;
  width: fit;
  position: absolute;
  margin-top: 0.5rem;
  ${({ theme }) =>
    theme.dark &&
    `
    background-color: #374151;
  `}

  @media (max-width: 760px) {
    right: 10px;
  }
`;

const SearchContainer = styled.div`
  padding: 0.75rem;
  background: white;
  position: sticky;
  top: 0;
  left: 0;
  border-bottom: 1px solid #d7dbee;
`;

const RelativeContainer = styled.div`
  position: relative;
`;

const IconContainer = styled.div`
  position: absolute;
  inset: 0;
  margin-left: 0.75rem;
  display: flex;
  align-items: center;
  pointer-events: none;
`;

const SearchInput = styled.input`
  width: 100%;
  padding: 0.5rem 0.5rem 0.5rem 2.5rem;
  font-size: 0.875rem;
  color: #1f2937;
  border: none;
  &:focus {
    outline: none;
    ring: 2px solid #3b82f6;
    border-color: #3b82f6;
  }
  ${({ theme }) =>
    theme.dark &&
    `
    color: #f9fafb;
    &::placeholder {
      color: #A8ADC7;
    }
  `}
`;

const List = styled.ul`
  padding: 1rem 0.75rem;
  font-size: 0.875rem;
  color: #374151;
  list-style: none;
  ${({ theme }) =>
    theme.dark &&
    `
    color: #e5e7eb;
  `};
`;

const ListItem = styled.li``;

const GradientCircle = styled.div`
  width: 25px;
  height: 25px;
  border-radius: 100%;
  background: linear-gradient(137.16deg, #f06464 17.4%, #3c809d 100%);
`;

const ListItemButton = styled.button<{ isActive: boolean }>`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 0.75rem 1rem;
  cursor: pointer;
  background: ${(props) => (props.isActive ? '#f5f7fb;' : 'transparent;')}
  border-radius: 6px;
  width: 100%;
  text-align: left;
  color: #2B2F44;
  font-weight: 500;
  outline-color: transparent;
  border: none;
  &:hover {
    background-color: #f5f7fb;
  }
  ${({ theme }) =>
    theme.dark &&
    `
    &:hover {
      background-color: #4b5563;
      color: #ffffff;
    }
  `}
`;

const SearchIcon = styled.svg`
  width: 1rem;
  height: 1rem;
  color: #6b7280;
  ${({ theme }) =>
    theme.dark &&
    `
    color: #9ca3af;
  `}
`;

const Button = styled.button`
  background: #f5f7fb;
  border: none;
  border-radius: 4px;
  padding: 4px 8px;
  margin-left: 10px;
  cursor: pointer;
  outline: none;
`;

const LoadMoreButton = styled(AntdButton)`
  background: #4f9eff;
  border-radius: 8px;
  box-shadow: none;
  color: white;
  border: none;
  height: 36px;

  &:hover,
  &:focus {
    background: #4f9eff;
    color: white;
  }
`;

export interface IDropdown {
  id: number;
  garageName: string;
  shopId?: string;
}

export interface IDropdownProps {
  selectedItem: IDropdown | null;
  onSelect?: (item: IDropdown) => void;
}

const Dropdown = () => {
  const dropdownRef = useRef<ElementRef<'div'>>(null);
  const { user, selectedGarage, setSelectedGarage } = useAuthContext();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [debouncedQuery, setDebouncedQuery] = useState(searchQuery);

  const [cursor, setCursor] = useState(null);
  const [limit, setLimit] = useState(20);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [dropdownGarages, setDropdownGarages] = useState<IDropdown[]>([]);

  const handleSelectDropdownItem = (item: IDropdown) => {
    setSelectedGarage(item);
  };

  const fetchGroupGarages = useCallback(
    async (_cursor?: number | null) => {
      if (user?.isGroup && hasMore && !loading) {
        setLoading(true);
        try {
          const response = await chatApiService.getGroupGarages({
            cursor: _cursor,
            limit,
            searchQuery,
          });

          const { garages: newGarages, nextCursor } = response.data.data;

          if (debouncedQuery) {
            setDropdownGarages(newGarages);
          } else {
            if (newGarages?.length > 0) {
              setDropdownGarages((prevGarages) => [
                ...prevGarages,
                ...newGarages,
              ]);

              setHasMore(!!nextCursor);
              setCursor(nextCursor);
            }
          }
        } catch (error) {
          console.error('Error fetching group garages:', error);
        } finally {
          setLoading(false);
        }
      }
    },
    [user?.isGroup, debouncedQuery]
  );

  useEffect(() => {
    fetchGroupGarages();
  }, [fetchGroupGarages]);

  const loadMore = () => {
    if (hasMore && !loading) {
      fetchGroupGarages(cursor);
    }
  };

  const debouncedSetQuery = useCallback(
    debounce((query) => {
      setDebouncedQuery(query);
    }, 500),
    []
  );

  const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
    debouncedSetQuery(event.target.value);
  };

  const handleScroll = () => {
    const container = dropdownRef.current;
    if (container && !debouncedQuery) {
      const scrollableHeight = container.scrollHeight;
      const viewportHeight = container.clientHeight;
      const scrollPosition = container.scrollTop;

      if (scrollableHeight - viewportHeight - scrollPosition < 50) {
        loadMore();
      }
    }
  };

  useEffect(() => {
    const container = dropdownRef.current;
    if (container) {
      container.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (container) {
        container.removeEventListener('scroll', handleScroll);
      }
    };
  }, [handleScroll, loading, hasMore]);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleItemClick = (item: IDropdown) => {
    if (handleSelectDropdownItem) handleSelectDropdownItem(item);
    setIsOpen(false);
  };

  return (
    <div>
      <Button onClick={toggleDropdown}>
        <DoubleChevron color="#464646" />
      </Button>

      {isOpen && (
        <DropdownContainer ref={dropdownRef}>
          <SearchContainer>
            <label htmlFor="input-group-search" className="sr-only">
              Search
            </label>
            <RelativeContainer>
              <IconContainer>
                <SearchIcon
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 20 20"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                  />
                </SearchIcon>
              </IconContainer>
              <SearchInput
                type="text"
                id="input-group-search"
                value={searchQuery}
                onChange={handleSearch}
                placeholder="Find a garage"
              />
            </RelativeContainer>
          </SearchContainer>
          <List>
            <h1
              style={{
                color: '#A8ADC7',
                paddingLeft: '0.75rem',
                fontSize: 12,
                fontWeight: 600,
              }}
            >
              GARAGES
            </h1>
            {[defaultSelectedGarage, ...dropdownGarages].map((item) => (
              <ListItem key={item.id}>
                <ListItemButton
                  role="button"
                  onClick={(e) => {
                    handleItemClick(item);
                  }}
                  isActive={item?.id === selectedGarage?.id}
                >
                  <GradientCircle />
                  {item?.garageName}

                  {item?.id === selectedGarage?.id && (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="15"
                      height="11"
                      viewBox="0 0 15 11"
                      fill="none"
                      style={{ marginLeft: 'auto' }}
                    >
                      <path
                        d="M14.3086 0.71875C14.5898 1.03125 14.5898 1.5 14.3086 1.78125L6.05859 10.0312C5.74609 10.3438 5.27734 10.3438 4.99609 10.0312L0.746094 5.78125C0.433594 5.5 0.433594 5.03125 0.746094 4.75C1.02734 4.4375 1.49609 4.4375 1.77734 4.75L5.49609 8.46875L13.2461 0.71875C13.5273 0.4375 13.9961 0.4375 14.2773 0.71875H14.3086Z"
                        fill="#2B2F44"
                      />
                    </svg>
                  )}
                </ListItemButton>
              </ListItem>
            ))}
          </List>

          <div style={{ padding: '0 1rem 1rem', textAlign: 'center' }}>
            {loading && <p>Loading...</p>}
            {hasMore && !loading && !debouncedQuery && (
              <LoadMoreButton onClick={loadMore}>Load More</LoadMoreButton>
            )}
            {!hasMore && !debouncedQuery && <p>No more garages to load</p>}
          </div>
        </DropdownContainer>
      )}
    </div>
  );
};

export default Dropdown;
