export const colors = {
    primary: '#2B2F44',
    white: '#FFFFFF',
    green: '#02E89D',
    grey: '#A1A2A7',
    magnolia: "#F4F5FB",
    azureishWhite: "#D7DBEE",
    secondary: "#23273C",
    red: "#DB2F2F",
}
