import EditorJS, { API } from '@editorjs/editorjs';
import { useEffect, useRef } from 'react';
import { EDITOR_JS_TOOLS } from './config';
import * as S from './styles';
import _t from '../../../locales';
import React from 'react';

import { createReactEditorJS } from 'react-editor-js';
import { useTranslation } from 'react-i18next';
// import { SimpleMentions } from './tag';
const Mention = require('@groupher/editor-mention');

type EditorProps = {
  editMode?: boolean;
  data?: Record<string, any>;
  id?: string;
  onChange?: (value: string, event?: any, error?: string) => void;
  contentLimit?: number | null;
};

const truncateOutputData = (
  outputData: Record<string, any>,
  keyToTruncate: string,
  limit: number
) => {
  const truncatedData: Record<string, any> = { ...outputData };

  // Check if the keyToTruncate exists in outputData and is an array
  if (Array.isArray(outputData[keyToTruncate])) {
    // Traverse the array and truncate string values within the specified key
    truncatedData[keyToTruncate] = outputData[keyToTruncate].map(
      (block: Record<string, any>) => {
        const truncatedBlock: Record<string, any> = { ...block };

        for (const blockKey in block) {
          if (
            Object.prototype.hasOwnProperty.call(block, blockKey) &&
            typeof block[blockKey] === 'string'
          ) {
            // Truncate the string values if they exceed the specified limit
            truncatedBlock[blockKey] = truncateString(block[blockKey], limit);
          }
        }

        return truncatedBlock;
      }
    );
  }

  return truncatedData;
};

const truncateString = (str: string, limit: number) => {
  if (str.length > limit) {
    const truncatedStr = str.substring(0, limit - 3) + '...';
    return truncatedStr;
  }

  return str;
};

// validation https://codex.so/editorjs-max-length
const Editor = ({
  editMode = true,
  data,
  id,
  onChange,
  contentLimit,
}: EditorProps) => {
  const editorRef = useRef(null);
  const { t } = useTranslation();
  useEffect(() => {
    if (editorRef.current && window) {
      new EditorJS({
        holder: id,
        data: data as any,
        placeholder: _t.t('dashboard.letWrite'),
        tools: { ...EDITOR_JS_TOOLS, mentions: Mention },
        inlineToolbar: true,
        readOnly: !editMode,
        onChange: async (api: API, event: CustomEvent) => {
          function couldBeCounted(block) {
            return 'text' in block.data;
          }

          function getBlocksTextLen(blocks) {
            return blocks.filter(couldBeCounted).reduce((sum, block) => {
              sum += block.data.text.length;

              return sum;
            }, 0);
          }

          if (contentLimit) {
            if (event.type !== 'block-changed') {
              // only count block modifications and skip events like 'block-added' etc
              return;
            }

            const limit = contentLimit;
            const content = await api.saver.save();
            const contentLen = getBlocksTextLen(content.blocks);

            // if (contentLen <= limit) {
            //   return;
            // }

            const workingBlock = event.detail.target;
            const workingBlockIndex = event.detail.index;
            const workingBlockSaved = content.blocks
              .filter((block) => block.id === workingBlock.id)
              .pop();
            const otherBlocks = content.blocks.filter(
              (block) => block.id !== workingBlock.id
            );
            const otherBlocksLen = getBlocksTextLen(otherBlocks);
            const workingBlockLimit = limit - otherBlocksLen;

            if (workingBlockSaved.data.text.length > workingBlockLimit) {
              // Truncate the exceeding text to the limit
              const truncatedText = workingBlockSaved.data.text.substr(
                0,
                workingBlockLimit
              );

              // Blur the remaining text
              const remainingText =
                workingBlockSaved.data.text.substr(workingBlockLimit);
              const blurredText = `<span style="color: grey; opacity: 1px; filter: blur(0.5px)">${remainingText}</span>`;

              // Update the content in the EditorJS instance
              api.blocks.update(workingBlock.id, {
                text: truncatedText + blurredText,
              });

              // onChange &&
              //   onChange(
              //     JSON.stringify(truncatedText),
              //     event,
              //     contentLen > limit
              //       ? `Character limit exceeded. Please limit your text to
              //     ${limit} characters.`
              //       : ''
              //   );
              api.saver.save().then((outputData: Record<string, any>) => {
                onChange &&
                  onChange(
                    JSON.stringify(
                      truncateOutputData(outputData, 'blocks', limit)
                    ),
                    event,
                    contentLen > limit
                      ? `${t('personalMessage.limitError')}
                  ${limit} ${t('personalMessage.characters')}.`
                      : ''
                  );
              });
            } else {
              api.saver.save().then((outputData: Record<string, any>) => {
                onChange && onChange(JSON.stringify(outputData), event);
              });
            }

            api.caret.setToBlock(workingBlockIndex, 'end');
          } else {
            api.saver.save().then((outputData: Record<string, any>) => {
              onChange && onChange(JSON.stringify(outputData), event);
            });
          }
        },
      });
    }
  }, []);
  return <S.EditorContainer id={id} ref={editorRef} editMode={editMode} />;
};

export default Editor;

const ReactEditorJS = createReactEditorJS();

export const ReactEditor = (props: any) => {
  return (
    <S.StyledEditor>
      <ReactEditorJS tools={{ ...EDITOR_JS_TOOLS }} {...props} />
    </S.StyledEditor>
  );
};
